import { AppDispatch } from "@ReduxService/index";
import {
  ICommissionsToPayForEmployee,
  IItemsToPayCommisionResponse,
} from "@ReduxService/states/commissionsToPayForEmployeeSlice";
import {
  updateNotificationStatus,
  updateNotificationMessage,
} from "@ReduxService/states/notificationSlice";

import { getCommissionsToPayForEmployee_service } from "@Services/Employee/getCommissionsToPayForEmployee.service";

export const getCommissionsToPayForEmployee_adapter = async (
  dispatch: AppDispatch,
  employeeId: number,
  startDate: string,
  endDate: string,
  commisionId: number
) => {
  const response = await getCommissionsToPayForEmployee_service(
    employeeId,
    startDate,
    endDate,
    commisionId
  );
  if (!response.isSuccess) {
    dispatch(updateNotificationMessage(response.message));
    dispatch(updateNotificationStatus(response.status));
    // dispatch(updateCommissionsToPayForEmployee([]));
    return null;
  } else {
    // commisionId": 59,
    //     "commisionDescription": "Comisión por servicios",
    //     "goal": 3000.00,
    //     "accumulated": 210.040,
    //     "startDate": "2024-11-01",
    //     "endDate": "2024-11-28",
    //     "progress": 7.0013333333333333333333333300,
    //     "totalPayCommision": 21.00400,
    const adaptedResponse: ICommissionsToPayForEmployee = {
      commisionId: response.data.commisionId,
      commisionName: response.data.commisionDescription,
      typeAmount: response.data.typeAmount,
      typeAmountDescription: response.data.typeAmountDescription,
      amount: response.data.totalPayCommision,
      goal: response.data.goal,
      accumulated: response.data.accumulated,
      startDate: response.data.startDate,
      endDate: response.data.endDate,
      progress: response.data.progress,
      totalPayCommision: response.data.totalPayCommision,
      methodId: response.data.methodId,
      methodDescription: response.data.methodDescription,
      itemsToPayCommisionResponse:
        response.data.itemsToPayCommisionResponse?.map(
          (item: IItemsToPayCommisionResponse) => ({
            documentId: item.documentId,
            documentNumber: item.documentNumber,
            documentDetailId: item.documentDetailId,
            description: item.description,
            priceServiceId: item.priceServiceId,
            localProductId: item.localProductId,
            productId: item.productId,
            packageId: item.packageId,
            packageDocumentDetailId: item.packageDocumentDetailId,
            grossAmount: item.grossAmount,
            taxDiscount: item.taxDiscount,
            generalDiscountProporcional: item.generalDiscountProporcional,
            totalPayItemCommision: item.totalPayItemCommision,
            baseAmountToPayCommision: item.baseAmountToPayCommision,
            itemQuantity: item.itemQuantity,
            creationDate: item.creationDate,
          })
        ),
    };
    // dispatch(updateCommissionsToPayForEmployee([adaptedResponse]));
    return adaptedResponse;
  }
};
