import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import style from "./EmployeeCommissions.module.css";
import {
  ButtonWithoutIcon,
  DateInput,
  Icon,
  ModalAcceptCancel,
  //@ts-ignore
} from "@viuti/recursos";
// import { HiOutlineCurrencyDollar } from "react-icons/hi2";

import { ArrowLeftIcon, LoadingPulseIcon } from "@Models/icons";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  HeaderContext,
  HeaderContextProps,
} from "@Hooks/useSitesHeaderContext";
import {
  handleChangeEndPeriod,
  handleChangeStartPeriod,
} from "./functions/handleChangePeriod";
import { getSalesCommissions } from "@Adapters/getSalesCommissions.adapter";
import { getListOfEmployeeCommissions_adapter } from "@Adapters/getListOfEmployeeCommissions/getListOfEmployeeCommissions.adapter";
import { getCommissionsToPayForEmployee_adapter } from "@Adapters/getListOfEmployeeCommissions/getCommissionsToPayForEmployee.adapter";
import Tabs from "@Components/ui/molecules/Tabs/Tabs";
import { ICommissionsToPayForEmployee } from "@ReduxService/states/commissionsToPayForEmployeeSlice";
import { formatToSoles } from "@Utilities/FormatsHandler";
import SaleDetailsModalChildren from "@Components/ui/molecules/SaleDetailsModal/SaleDetailsModalChildren/SaleDetailsModalChildren";
import { postPayCommisionEmployee_adapter } from "@Adapters/postPayCommisionEmployee/postPayCommisionEmployee.adapter";
import { getHistoryEmployeeCommissions_adapter } from "@Adapters/commissions/getHistoryEmployeeCommissions.adapter";
import { getCommmisionEmployeeDetail_adapter } from "@Adapters/commissions/getCommissionEmployeeDetail.adapter";

const EmployeeCommissions = () => {
  const { idEmployee } = useParams();
  const dispatch = useAppDispatch();
  const headerContext = useContext(HeaderContext) as HeaderContextProps;
  const { listOfEmployeeCommissions } = useAppSelector(
    (state) => state.listOfEmployeeCommissions
  );
  // const { commissionsToPayForEmployee } = useAppSelector(
  //   (state) => state.commissionsToPayForEmployee
  // );
  const [loaderCommissions, setLoaderCommissions] = useState(false);

  const [tableFetchStatus, setTableFetchStatus] = useState(null);

  const [salesPeriod, setSalesPeriod] = useState(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    return {
      startPeriod: firstDayOfMonth.toISOString().split("T")[0],
      endPeriod: today.toISOString().split("T")[0],
    };
  });

  const [selectedCommission, setSelectedCommission] = useState<number | null>(
    null
  );

  const [activeTab, setActiveTab] = useState("pending");
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showDetail, setShowDetail] = useState(false);

  const [commissionData, setCommissionData] =
    useState<ICommissionsToPayForEmployee | null>(null);

  const [loading, setLoading] = useState(false);

  const [progressData, setProgressData] = useState({
    currentAmount: 0,
    percentage: 0,
    isGoalMet: false,
    totalPayCommision: 0,
  });

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const [historyData, setHistoryData] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [historyPage, setHistoryPage] = useState(1);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);

  const [showCommissionDetailsModal, setShowCommissionDetailsModal] =
    useState(false);
  const [commissionDetails, setCommissionDetails] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const [showAllServices, setShowAllServices] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);

  const handleCommissionClick = async (commissionId: number) => {
    setLoading(true);
    setSelectedCommission(commissionId);
    setShowDetail(true);
    setSelectedRows([]);
    setHistoryData([]);
    setActiveTab("pending");

    const response = await getCommissionsToPayForEmployee_adapter(
      dispatch,
      Number(idEmployee),
      salesPeriod.startPeriod,
      salesPeriod.endPeriod,
      commissionId
    );

    setProgressData({
      currentAmount: response?.accumulated || 0,
      percentage: response?.progress || 0,
      isGoalMet: (response?.accumulated || 0) >= (response?.goal || 0),
      totalPayCommision: response?.totalPayCommision || 0,
    });

    setCommissionData(response);
    setLoading(false);
  };

  const handleBack = () => {
    setShowDetail(false);
    setSelectedCommission(null);
    setCommissionData(null);
  };

  const getReporteVentasData = async () => {
    if (salesPeriod.startPeriod && salesPeriod.endPeriod) {
      setTableFetchStatus(0);
      setLoading(true);

      const response = await getSalesCommissions(
        dispatch,
        Number(idEmployee),
        salesPeriod.startPeriod,
        salesPeriod.endPeriod
      );

      if (selectedCommission) {
        const commissionResponse = await getCommissionsToPayForEmployee_adapter(
          dispatch,
          Number(idEmployee),
          salesPeriod.startPeriod,
          salesPeriod.endPeriod,
          selectedCommission
        );

        setProgressData({
          currentAmount: commissionResponse?.accumulated || 0,
          percentage: commissionResponse?.progress || 0,
          isGoalMet:
            (commissionResponse?.accumulated || 0) >=
            (commissionResponse?.goal || 0),
          totalPayCommision: commissionResponse?.totalPayCommision || 0,
        });

        setCommissionData(commissionResponse);
      }

      setTableFetchStatus(response.status);
      setLoading(false);
    }
  };

  const isSubmitBttnDisabled =
    salesPeriod.startPeriod === "" ||
    salesPeriod.endPeriod === "" ||
    salesPeriod.startPeriod > salesPeriod.endPeriod;

  const handleTabChange = async (tab: string) => {
    setActiveTab(tab);
    if (tab === "history" && selectedCommission) {
      setIsHistoryLoading(true);
      try {
        const response = await getHistoryEmployeeCommissions_adapter(
          Number(idEmployee),
          historyPage,
          selectedCommission
        );

        if (response.isSuccess) {
          setHistoryData(response.data);
        }
      } catch (error) {
        console.error("Error al cargar el historial:", error);
      } finally {
        setIsHistoryLoading(false);
      }
    }
  };

  const handleRowSelect = (index: number) => {
    setSelectedRows((prev) =>
      prev.includes(index)
        ? prev.filter((rowIndex) => rowIndex !== index)
        : [...prev, index]
    );
  };

  const selectAllRows = () => {
    if (
      selectedRows.length === commissionData?.itemsToPayCommisionResponse.length
    ) {
      setSelectedRows([]);
    } else {
      setSelectedRows(
        Array.from(
          { length: commissionData?.itemsToPayCommisionResponse.length || 0 },
          (_, i) => i
        )
      );
    }
  };

  const handlePayCommissions = () => {
    setShowPaymentModal(true);
  };

  const handleCloseModal = () => {
    setShowPaymentModal(false);
  };

  const getSelectedCommissionsTotal = () => {
    return (
      commissionData?.itemsToPayCommisionResponse
        ?.filter((_, index) => selectedRows.includes(index))
        .reduce((sum, item) => sum + item.totalPayItemCommision, 0) || 0
    );
  };

  const handlePaymentConfirmation = async () => {
    setIsPaymentLoading(true);

    const paymentData = {
      commisionId: selectedCommission,
      employeeId: Number(idEmployee),
      startDate: salesPeriod.startPeriod,
      endDate: salesPeriod.endPeriod,
      documentDetails: commissionData?.itemsToPayCommisionResponse
        .filter((_, index) => selectedRows.includes(index))
        .map((item) => ({
          documentId: item.documentId,
          documentDetailId: item.documentDetailId,
          documentDetailPackage: item.packageDocumentDetailId || null,
        })),
    };

    const response = await postPayCommisionEmployee_adapter(
      paymentData,
      dispatch
    );

    if (response?.isSuccess) {
      await getReporteVentasData();
    }

    handleCloseModal();
    setSelectedRows([]);
    setIsPaymentLoading(false);
  };

  const handleDescriptionClick = (item) => {
    setSelectedDetail(item);
    setShowDetailsModal(true);
  };

  const handleCommissionDetailsClick = async () => {
    if (selectedCommission) {
      setIsLoadingDetails(true);
      try {
        const response = await getCommmisionEmployeeDetail_adapter(
          selectedCommission
        );
        if (response.isSuccess) {
          setCommissionDetails(response.data);
          setShowCommissionDetailsModal(true);
        } else {
          setCommissionDetails({
            error: true,
            message:
              "No se pudo cargar la información de la comisión. Por favor, intente nuevamente.",
          });
          setShowCommissionDetailsModal(true);
        }
      } catch (error) {
        setCommissionDetails({
          error: true,
          message:
            "Ocurrió un error al obtener los detalles de la comisión. Por favor, intente nuevamente.",
        });
        setShowCommissionDetailsModal(true);
      } finally {
        setIsLoadingDetails(false);
      }
    }
  };

  useEffect(() => {
    headerContext.handleIsHidden(true);
    headerContext.handleIsDisabled(true);
    headerContext.handleTextBtnChange("");
    headerContext.handleClickChange(() => {});
    getReporteVentasData();
    getListOfEmployeeCommissions_adapter(
      dispatch,
      Number(idEmployee),
      setLoaderCommissions
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loaderCommissions) {
    return (
      <div className={style.loaderContainer}>
        <img src={LoadingPulseIcon} alt="Cargando..." />
      </div>
    );
  }

  return (
    <div className={style.view__container}>
      {!showDetail ? (
        <>
          <h1 className={style.mainTitle}>Comisiones disponibles</h1>
          <p className={style.subtitle}>
            Selecciona una comisión para ver pagos
          </p>

          <div className={style.commissionsGrid}>
            {listOfEmployeeCommissions.length === 0 ? (
              <p className={style.noCommissions}>
                No hay comisiones disponibles para este empleado
              </p>
            ) : (
              listOfEmployeeCommissions.map((commission) => (
                <button
                  key={commission.commisionId}
                  className={`${style.commissionCard} ${
                    selectedCommission === commission.commisionId
                      ? style.selected
                      : ""
                  }`}
                  onClick={() => handleCommissionClick(commission.commisionId)}
                >
                  {/* <div className={style.cardHeader}>
                    <span></span>
                  </div> */}
                  <div className={style.cardBody}>
                    <h2>{commission.commisionName}</h2>
                    <span className={style.description}>
                      {commission.methodDescription}
                    </span>
                    <div className={style.subtypeContainer}>
                      <span className={style.subtype}>
                        {commission.typeAmountDescription}{" "}
                        {`${
                          commission.typeAmountDescription === "Monto fijo"
                            ? `${formatToSoles(commission.amount)}`
                            : `${commission.amount}%`
                        }`}
                      </span>
                    </div>
                    {commission.goal > 0 && (
                      <p className={style.goal}>
                        Meta: {formatToSoles(commission.goal)}
                      </p>
                    )}
                  </div>
                </button>
              ))
            )}
          </div>
        </>
      ) : (
        <div className={style.detailSection}>
          <div className={style.detailHeader}>
            <div className={style.detailHeaderContent}>
              <button onClick={handleBack} className={style.titleBackButton}>
                <Icon
                  className={style.titleBackIcon}
                  path={ArrowLeftIcon}
                  size={18}
                  alt="volver"
                  color="#45348e"
                />
              </button>
              <div>
                <button
                  className={`${style.detailTitle} ${style.clickableTitle}`}
                  onClick={handleCommissionDetailsClick}
                >
                  {
                    listOfEmployeeCommissions.find(
                      (c) => c.commisionId === selectedCommission
                    )?.commisionName
                  }
                  {/* <HiOutlineCurrencyDollar /> */}
                </button>
                <p className={style.detailSubtitle}>
                  {activeTab === "pending"
                    ? "Comisiones disponibles según el periodo seleccionado"
                    : "Historial de pagos realizados"}
                </p>
              </div>
            </div>
            {activeTab === "pending" && (
              <div className={style.periodSearchBarContent}>
                <DateInput
                  label="Desde"
                  inputProps={{
                    value: salesPeriod.startPeriod,
                    onChange: (e) => handleChangeStartPeriod(e, setSalesPeriod),
                    placeholder: "Seleccionar fecha",
                  }}
                />
                <DateInput
                  label="Hasta"
                  inputProps={{
                    value: salesPeriod.endPeriod,
                    onChange: (e) => handleChangeEndPeriod(e, setSalesPeriod),
                    placeholder: "Seleccionar fecha",
                  }}
                />
                <ButtonWithoutIcon
                  isPrimary={false}
                  textBttn="Cargar"
                  handleClick={getReporteVentasData}
                  isDisabled={isSubmitBttnDisabled}
                />
              </div>
            )}
          </div>
          {!loading && commissionData && (
            <>
              {commissionData.goal > 0 ? (
                <div className={style.progressSection}>
                  <h3>Progreso de Meta</h3>
                  <div className={style.progressBar}>
                    <div
                      className={style.progressFill}
                      style={{ width: `${progressData.percentage}%` }}
                    />
                  </div>
                  <div className={style.goalStatus}>
                    <div className={style.goalInfo}>
                      <div className={style.goalDetail}>
                        <span>Acumulado:</span>
                        <strong>
                          {formatToSoles(progressData.currentAmount)}
                        </strong>
                      </div>
                      <div className={style.goalDetail}>
                        <span>Meta:</span>
                        <strong>{formatToSoles(commissionData.goal)}</strong>
                      </div>
                      <div className={style.goalDetail}>
                        <span>Comisión a pagar:</span>
                        <strong>
                          {formatToSoles(progressData.totalPayCommision)}
                        </strong>
                      </div>
                    </div>
                    <div className={style.progressInfo}>
                      <span
                        className={`${style.goalBadge} ${
                          progressData.isGoalMet
                            ? style.goalMet
                            : style.goalPending
                        }`}
                      >
                        {progressData.isGoalMet
                          ? "Meta Cumplida"
                          : "Meta Pendiente"}
                      </span>
                      <p className={style.progressPercentage}>
                        Progreso: {progressData.percentage.toFixed(1)}%
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )}
          {loading ? (
            <div className={style.loaderContainer}>
              <img src={LoadingPulseIcon} alt="Cargando..." />
            </div>
          ) : (
            <div>
              <div className={style.tabsContainer}>
                <Tabs
                  tabs={[
                    { id: "pending", label: "Pagos Pendientes" },
                    { id: "history", label: "Historial de Pagos" },
                  ]}
                  activeTab={activeTab}
                  onTabChange={handleTabChange}
                />
              </div>

              {/* Tabla de Pagos Pendientes */}
              {activeTab === "pending" && (
                <>
                  <table className={style.table}>
                    <thead>
                      <tr>
                        {commissionData?.itemsToPayCommisionResponse?.length >
                          0 && (
                          <th>
                            <input
                              type="checkbox"
                              onChange={selectAllRows}
                              checked={
                                commissionData?.itemsToPayCommisionResponse
                                  ?.length > 0 &&
                                selectedRows.length ===
                                  commissionData?.itemsToPayCommisionResponse
                                    ?.length
                              }
                            />
                          </th>
                        )}
                        <th>Documento</th>
                        <th>Fecha</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Monto Base</th>
                        <th>Comisión</th>
                      </tr>
                    </thead>
                    <tbody>
                      {commissionData?.itemsToPayCommisionResponse?.length ===
                      0 ? (
                        <tr>
                          <td colSpan={7} style={{ textAlign: "center" }}>
                            <p>
                              No hay comisiones disponibles para este empleado
                            </p>
                          </td>
                        </tr>
                      ) : (
                        commissionData?.itemsToPayCommisionResponse?.map(
                          (item, index) => (
                            <tr key={item.documentDetailId}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedRows.includes(index)}
                                  onChange={() => handleRowSelect(index)}
                                />
                              </td>
                              <td data-label="Documento">
                                {item.documentNumber}
                              </td>
                              <td data-label="Fecha">{item.creationDate}</td>
                              <td data-label="Descripción">
                                <button
                                  onClick={() => handleDescriptionClick(item)}
                                  className={style.clickableDescription}
                                >
                                  {item.description}
                                </button>
                              </td>
                              <td data-label="Cantidad">
                                {item.itemQuantity} unidades
                              </td>
                              <td data-label="Monto Base">
                                S/. {item.baseAmountToPayCommision.toFixed(2)}
                              </td>
                              <td data-label="Comisión">
                                S/. {item.totalPayItemCommision.toFixed(2)}
                              </td>
                            </tr>
                          )
                        )
                      )}
                    </tbody>
                  </table>

                  {commissionData?.itemsToPayCommisionResponse?.length > 0 && (
                    <div className={style.actionButton}>
                      <ButtonWithoutIcon
                        isPrimary
                        textBttn={
                          selectedRows.length === 0
                            ? "Pagar comisiones seleccionadas"
                            : `Pagar ${formatToSoles(
                                getSelectedCommissionsTotal()
                              )}`
                        }
                        handleClick={handlePayCommissions}
                        isDisabled={selectedRows.length === 0}
                      />
                    </div>
                  )}
                </>
              )}

              {/* Tabla de Historial */}
              {activeTab === "history" && (
                <>
                  {isHistoryLoading ? (
                    <div className={style.loaderContainer}>
                      <img src={LoadingPulseIcon} alt="Cargando..." />
                    </div>
                  ) : (
                    <table className={style.table}>
                      <thead>
                        <tr>
                          <th>Fecha de Pago</th>
                          <th>Periodo</th>
                          <th>Meta</th>
                          <th>Progreso</th>
                          <th>Tipo</th>
                          <th>Monto Pagado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyData.length === 0 ? (
                          <tr>
                            <td colSpan={6} style={{ textAlign: "center" }}>
                              <p>No hay historial de pagos disponible</p>
                            </td>
                          </tr>
                        ) : (
                          historyData.map((item) => (
                            <tr
                              key={item.paymentCommisionId}
                              className={style.historyRow}
                            >
                              <td
                                className={style.historyCell}
                                data-label="Fecha de Pago"
                              >
                                <span className={style.historyDate}>
                                  {item.dateCreation}
                                </span>
                              </td>
                              <td
                                className={style.historyCell}
                                data-label="Periodo"
                              >
                                <span
                                  className={style.historyDate}
                                >{`${item.startDate} - ${item.endDate}`}</span>
                              </td>
                              <td
                                className={style.historyCell}
                                data-label="Meta"
                              >
                                <span className={style.historyAmount}>
                                  {formatToSoles(item.goal)}
                                </span>
                              </td>
                              <td
                                className={style.historyCell}
                                data-label="Progreso"
                              >
                                <span
                                  className={style.historyProgress}
                                >{`${item.progress}%`}</span>
                              </td>
                              <td
                                className={style.historyCell}
                                data-label="Tipo"
                              >
                                <span>{`${item.typeAmountDescription} - ${
                                  item.typeAmountDescription === "Monto fijo"
                                    ? formatToSoles(item.amount)
                                    : `${item.amount}%`
                                }`}</span>
                              </td>
                              <td
                                className={style.historyCell}
                                data-label="Monto Pagado"
                              >
                                <span className={style.historyAmount}>
                                  {formatToSoles(item.totalPayment)}
                                </span>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
      {showPaymentModal && (
        <ModalAcceptCancel
          title="Confirmar pago"
          showButtons={{
            showButtonClose: true,
            showButtonOne: true,
            showButtonTwo: true,
          }}
          children={
            <div className={style.modalContent}>
              <p className={style.modalText}>
                ¿Estás seguro de que deseas pagar las comisiones seleccionadas?
              </p>
              <div className={style.modalSummary}>
                <div className={style.summaryItem}>
                  <span>Comisiones seleccionadas:</span>
                  <strong>{selectedRows.length}</strong>
                </div>
                <div className={style.summaryItem}>
                  <span>Comisión total:</span>
                  <strong>
                    {formatToSoles(getSelectedCommissionsTotal())}
                  </strong>
                </div>
              </div>
            </div>
          }
          visibility={showPaymentModal}
          buttonOne={{
            textButtonOne: "Cancelar",
            actionButtonOne: async () => {
              handleCloseModal();
            },
            isDisabled: false,
            isLoading: false,
          }}
          buttonTwo={{
            textButtonTwo: "Confirmar pago",
            actionButtonTwo: handlePaymentConfirmation,
            isDisabled: isPaymentLoading,
            isLoading: isPaymentLoading,
          }}
          actionButtonClose={handleCloseModal}
        />
      )}
      {showDetailsModal && selectedDetail && (
        <ModalAcceptCancel
          title="Detalles del documento"
          showButtons={{
            showButtonClose: true,
            showButtonOne: false,
            showButtonTwo: true,
          }}
          children={
            <div className={style.modalContent}>
              <div className={style.detailsGrid}>
                <div className={style.detailItem}>
                  <span>Documento:</span>
                  <strong>{selectedDetail.documentNumber}</strong>
                </div>
                <div className={style.detailItem}>
                  <span>Fecha:</span>
                  <strong>{selectedDetail.creationDate}</strong>
                </div>
                <div className={style.detailItem}>
                  <span>Cantidad:</span>
                  <strong>{selectedDetail.itemQuantity} unidades</strong>
                </div>
                <div className={style.detailItem}>
                  <span>Monto Base:</span>
                  <strong>
                    {formatToSoles(selectedDetail.baseAmountToPayCommision)}
                  </strong>
                </div>
                <div className={style.detailItem}>
                  <span>Comisión a pagar:</span>
                  <strong>
                    {formatToSoles(selectedDetail.totalPayItemCommision)}
                  </strong>
                </div>
                <div className={style.detailItem}>
                  <span>Descripción:</span>
                  <strong>{selectedDetail.description}</strong>
                </div>
              </div>
            </div>
          }
          visibility={showDetailsModal}
          buttonTwo={{
            textButtonTwo: "Cerrar",
            actionButtonTwo: () => setShowDetailsModal(false),
            isDisabled: false,
            isLoading: false,
          }}
          actionButtonClose={() => setShowDetailsModal(false)}
        />
      )}
      {showCommissionDetailsModal && (
        <ModalAcceptCancel
          title="Detalles de la Comisión"
          showButtons={{
            showButtonClose: true,
            showButtonOne: false,
            showButtonTwo: true,
          }}
          children={
            <div className={style.modalContent}>
              {isLoadingDetails ? (
                <div className={style.loaderContainer}>
                  <img src={LoadingPulseIcon} alt="Cargando..." />
                </div>
              ) : commissionDetails?.error ? (
                <div className={style.errorContainer}>
                  <p className={style.errorMessage}>
                    {commissionDetails.message}
                  </p>
                </div>
              ) : (
                <div className={style.commissionDetailsContainer}>
                  {/* Información General */}
                  <section className={style.detailSection}>
                    <div className={style.detailsGrid}>
                      <div className={style.detailItem}>
                        <span>Nombre de la comisión:</span>
                        <strong>{commissionDetails?.commisionName}</strong>
                      </div>
                      <div className={style.detailItem}>
                        <span>Método de comisión:</span>
                        <strong>{commissionDetails?.methodDescription}</strong>
                      </div>
                      <div className={style.detailItem}>
                        <span>Tipo de descuento:</span>
                        <strong>
                          {commissionDetails?.typeDiscountDescription}
                        </strong>
                      </div>
                      <div className={style.detailItem}>
                        <span>Valor:</span>
                        <strong>
                          {commissionDetails?.amount}
                          {commissionDetails?.typeDiscountDescription ===
                          "Monto fijo"
                            ? " S/"
                            : "%"}
                        </strong>
                      </div>
                    </div>
                  </section>

                  {/* Metas y Condiciones */}
                  <section className={style.detailSection}>
                    <div className={style.detailsGrid}>
                      <div className={style.detailItem}>
                        <span>Meta a alcanzar:</span>
                        <strong>
                          {formatToSoles(commissionDetails?.goal)}
                        </strong>
                      </div>
                      <div className={style.detailItem}>
                        <span>Aplica a promociones:</span>
                        <strong className={style.badge}>
                          {commissionDetails?.condicionPromotion ? "Sí" : "No"}
                        </strong>
                      </div>
                      <div className={style.detailItem}>
                        <span>Aplica a impuestos:</span>
                        <strong className={style.badge}>
                          {commissionDetails?.condicionTax ? "Sí" : "No"}
                        </strong>
                      </div>
                    </div>
                  </section>

                  {/* Servicios Incluidos */}
                  <section className={style.detailSection}>
                    <div className={style.detailsGrid}>
                      <div className={style.detailItem}>
                        <span>Servicios Incluidos:</span>
                        {!commissionDetails?.servicios?.length ? (
                          <strong className={style.emptyMessage}>
                            No hay servicios asociados a esta comisión
                          </strong>
                        ) : (
                          <div className={style.itemsList}>
                            {(showAllServices
                              ? commissionDetails.servicios
                              : commissionDetails.servicios.slice(0, 3)
                            ).map((servicio, index) => (
                              <strong key={index} className={style.itemDetail}>
                                {servicio.serviceName}
                              </strong>
                            ))}
                            {commissionDetails.servicios.length > 3 && (
                              <button
                                onClick={() =>
                                  setShowAllServices(!showAllServices)
                                }
                                className={style.viewMoreLink}
                              >
                                {showAllServices
                                  ? "Ver menos"
                                  : `Ver ${
                                      commissionDetails.servicios.length - 3
                                    } más`}
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </section>

                  {/* Productos Incluidos */}
                  <section className={style.detailSection}>
                    <div className={style.detailsGrid}>
                      <div className={style.detailItem}>
                        <span>Productos Incluidos:</span>
                        {!commissionDetails?.productos?.length ? (
                          <strong className={style.emptyMessage}>
                            No hay productos asociados a esta comisión
                          </strong>
                        ) : (
                          <div className={style.itemsList}>
                            {(showAllProducts
                              ? commissionDetails.productos
                              : commissionDetails.productos.slice(0, 3)
                            ).map((producto, index) => (
                              <strong key={index} className={style.itemDetail}>
                                {producto.productName}
                              </strong>
                            ))}
                            {commissionDetails.productos.length > 3 && (
                              <button
                                onClick={() =>
                                  setShowAllProducts(!showAllProducts)
                                }
                                className={style.viewMoreLink}
                              >
                                {showAllProducts
                                  ? "Ver menos"
                                  : `Ver ${
                                      commissionDetails.productos.length - 3
                                    } más`}
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </section>

                  {/* Información de Creación */}
                  <section className={style.detailSection}>
                    <div className={style.detailsGrid}>
                      <div className={style.detailItem}>
                        <span>Creado por:</span>
                        <strong>
                          {commissionDetails?.employeeCreationName}
                        </strong>
                      </div>
                      <div className={style.detailItem}>
                        <span>Fecha de creación:</span>
                        <strong>{commissionDetails?.dateCreation}</strong>
                      </div>
                    </div>
                  </section>
                </div>
              )}
            </div>
          }
          visibility={showCommissionDetailsModal}
          buttonTwo={{
            textButtonTwo: "Cerrar",
            actionButtonTwo: () => {
              setShowCommissionDetailsModal(false);
              setCommissionDetails(null);
            },
            isDisabled: false,
            isLoading: false,
          }}
          actionButtonClose={() => {
            setShowCommissionDetailsModal(false);
            setCommissionDetails(null);
          }}
        />
      )}
    </div>
  );
};

export default EmployeeCommissions;
