import { getEmployeeServicesAdapter } from "@Adapters/getEmployees/getEmployees.adapter";
import { getPositionsAdapter } from "@Adapters/getPositions/getPositions.adapter";
import { getEmployeeSchedulesAdapter } from "@Adapters/getSchedules/getSchedules.adapter";
import { AppDispatch } from "@ReduxService/index";

export const getEmployeeInfo = async (
  idEmployee: number,
  setPromiseStatus: Function,
  dispatch: AppDispatch
) => {
  try {
    const [employeeServices, employeeSchedules, companyRoles] =
      await Promise.all([
        getEmployeeServicesAdapter(idEmployee),
        getEmployeeSchedulesAdapter(idEmployee),
        getPositionsAdapter(dispatch),
      ]);

    setPromiseStatus({
      employeeServices: {
        isSuccess: employeeServices.isSuccess,
        status: employeeServices.status,
        data: employeeServices.data,
      },
      employeeSchedules: {
        isSuccess: employeeSchedules.isSuccess,
        status: employeeSchedules.status,
        data: employeeSchedules.data,
      },
      companyRoles: {
        isSuccess: companyRoles.isSuccess,
        status: companyRoles.status,
        data: companyRoles.data,
      },
    });
  } catch (error) {
    setPromiseStatus({
      employeeServices: {
        status: 500,
        data: null,
      },
      employeeSchedules: {
        status: 500,
        data: null,
      },
      companyRoles: {
        status: 500,
        data: null,
      },
    });
  }
};
