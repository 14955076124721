import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./EmployeeRow.module.css";
// @ts-ignore
import { Icon, IconWithOptions, ModalAcceptCancel } from "@viuti/recursos";
import { PaginationWrapper } from "../../../../recursos";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOfEachWord,
} from "@Utilities/texts";
import { deleteEmployeeInviteAdapter } from "@Adapters/employeesInvites.adapter";
import { IEmployee } from "@Models/interfaces/employees";
import {
  CommonArrowRightIcon,
  CurveArrowIcon,
  TrashBinIcon,
  TripleDotsIcon,
  WarningTriangleIcon,
} from "@Models/icons";
import { AppDispatch } from "@ReduxService/index";
import { handleSubmitInvite } from "./functions/handleSubmitInvite";
import { revokeAccess_adapter } from "@Adapters/editEmployees/revokeAccess.adapter";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { giveAccess_adapter } from "@Adapters/editEmployees/giveAccess.adapter";
import { deleteInvitationEmployee_adapter } from "@Adapters/deleteInvitationEmployee/deleteInvitationEmployee.adapter";

const ConfirmationModal = ({
  isVisible,
  setIsVisible,
  title,
  message,
  onConfirm,
  isLoading,
}) => {
  return (
    <ModalAcceptCancel
      title={title}
      visibility={{
        isVisible,
        setIsVisible,
      }}
      showButtons={{
        showButtonOne: true,
        showButtonTwo: true,
        showButtonClose: false,
      }}
      buttonOne={{
        textButtonOne: "Cancelar",
        actionButtonOne: () => setIsVisible(false),
        isDisabled: false,
        isLoading: false,
      }}
      buttonTwo={{
        textButtonTwo: "Confirmar",
        actionButtonTwo: onConfirm,
        isDisabled: isLoading,
        isLoading: isLoading,
      }}
    >
      <div className={style.body}>
        <div className={style.containerForm}>
          <figure>
            <img src={WarningTriangleIcon} alt="Símbolo de advertencia" />
          </figure>
          <p className={style.message}>{message}</p>
        </div>
      </div>
    </ModalAcceptCancel>
  );
};

const DeleteInvitationModal = ({
  isVisible,
  setIsVisible,
  employee,
  onConfirm,
  isLoading,
}) => (
  <ConfirmationModal
    isVisible={isVisible}
    setIsVisible={setIsVisible}
    title="Eliminar invitación"
    message={`
      ¿Estás seguro de que deseas eliminar esta invitación?
      ${employee?.email}
    `}
    onConfirm={onConfirm}
    isLoading={isLoading}
  />
);

const RevokeAccessModal = ({
  isVisible,
  setIsVisible,
  employee,
  onConfirm,
  isLoading,
}) => (
  <ConfirmationModal
    isVisible={isVisible}
    setIsVisible={setIsVisible}
    title="Revocar acceso al sistema"
    message={`
      ¿Estás seguro de que deseas revocar el acceso al sistema para ${employee?.name}?
      Esta acción impedirá que el empleado acceda al sistema.
    `}
    onConfirm={onConfirm}
    isLoading={isLoading}
  />
);

const GrantAccessModal = ({
  isVisible,
  setIsVisible,
  employee,
  onConfirm,
  isLoading,
}) => (
  <ConfirmationModal
    isVisible={isVisible}
    setIsVisible={setIsVisible}
    title="Dar acceso al sistema"
    message={`
      ¿Estás seguro de que deseas dar acceso al sistema a ${employee?.name}?
      Se le enviará un correo a "${employee?.email}" para que pueda crear su nueva contraseña.
    `}
    onConfirm={onConfirm}
    isLoading={isLoading}
  />
);

const renderEmployee = (
  employee: IEmployee,
  navigate,
  dispatch: AppDispatch,
  resendStatus,
  setResendStatus,
  setModalDeleteInvitation,
  setModalRevokeAccess,
  setModalGrantAccess,
  setSelectedEmployee
) => {
  const ADMINISTRATOR = "ADMINISTRADOR";

  const getInitial = (string: string) => {
    if (string) {
      return string.charAt(0).toUpperCase();
    } else {
      return null;
    }
  };

  let userWithAccess = !employee.userWithAccess;
  let isOnBoardingComplete = !!employee.onboardingDone || userWithAccess;

  return (
    <li key={employee.idEmployee} className={style.container}>
      <button
        className={
          isOnBoardingComplete
            ? style.containerEmpleadoTablaHover
            : style.containerEmpleadoTabla
        }
        onClick={() => {
          if (isOnBoardingComplete) {
            navigate(`/editar-empleados/${employee.idEmployee}`);
          }
        }}
      >
        <div className={style.empleado}>
          {employee.profilePicture?.length > 0 ? (
            <div
              className={style.profilePicture}
              style={{ backgroundImage: `url(${employee.profilePicture})` }}
            ></div>
          ) : (
            <div className={style.circleAvatar}>
              {getInitial(
                isOnBoardingComplete ? employee.name : employee.email
              )}
            </div>
          )}
          <div className={style.contenedorNombre}>
            <h3 className={style.nombre}>
              {isOnBoardingComplete
                ? capitalizeFirstLetterOfEachWord(
                    `${employee.name} ${employee.surname}`
                  )
                : employee.email}
            </h3>
            {isOnBoardingComplete && employee.userWithAccess && (
              <p className={style.cargo}>
                ({capitalizeFirstLetter(employee.position)})
              </p>
            )}
            {isOnBoardingComplete && !employee.userWithAccess && (
              <span className={style.userStatusTag}>Sin acceso al sistema</span>
            )}
          </div>
        </div>
        {!isOnBoardingComplete && (
          <div className={style.containerIcons}>
            <span className={style.inviteTagContainer}>Invitación enviada</span>
            <button
              className={style.resendBubbleContainer}
              style={{
                cursor: resendStatus[employee.idEmployee]
                  ? "not-allowed"
                  : "pointer",
                opacity: resendStatus[employee.idEmployee] ? 0.5 : 1,
              }}
              title="Reenviar invitación"
              onClick={() =>
                handleSubmitInvite(
                  employee,
                  resendStatus,
                  setResendStatus,
                  dispatch
                )
              }
            >
              <Icon path={CurveArrowIcon} color={"#45348E"} size={16} />
            </button>
            <button
              className={style.deleteBubbleContainer}
              onClick={() => {
                setSelectedEmployee(employee);
                setModalDeleteInvitation(true);
              }}
              title="Eliminar invitación del empleado"
            >
              <Icon path={TrashBinIcon} color={"#C36363"} size={16} />
            </button>
          </div>
        )}

        {isOnBoardingComplete && (
          <div
            className={`${style.selectBubbleContainer} ${style.containerIconsHide}`}
          >
            <img src={CommonArrowRightIcon} alt="editar" />
          </div>
        )}
      </button>
      {employee?.position?.toUpperCase() !== ADMINISTRATOR &&
        isOnBoardingComplete && (
          <span className={style.icon_options_container}>
            <IconWithOptions
              buttonsStyles={{
                padding: "20px",
              }}
              containerStyles={{
                borderRadius: "6px",
              }}
              mainButtonStyles={{
                height: "40px",
                width: "40px",
              }}
              icon={
                <span className={style.icon_options}>
                  <Icon path={TripleDotsIcon} size={20} alt={"Tres puntos"} />
                </span>
              }
              actions={[
                {
                  label:
                    isOnBoardingComplete && employee.userWithAccess
                      ? "Revocar acceso al sistema"
                      : "Dar acceso al sistema",
                  handler: () => {
                    setSelectedEmployee(employee);
                    if (isOnBoardingComplete && employee.userWithAccess) {
                      setModalRevokeAccess(true);
                    } else {
                      setModalGrantAccess(true);
                    }
                  },
                },
              ]}
            />
          </span>
        )}
    </li>
  );
};

const EmployeeRow = ({
  filteredEmployees,
  dispatch,
  currentPagination,
  setCurrentPagination,
  elementsPerPage,
  employeesState,
  options,
}: {
  filteredEmployees: any[];
  dispatch: AppDispatch;
  currentPagination: number;
  setCurrentPagination: any;
  elementsPerPage: number;
  employeesState: any[];
  options: any[];
}) => {
  const navigate = useNavigate();
  const [resendStatus, setResendStatus] = useState({});
  const [modalDeleteInvitation, setModalDeleteInvitation] = useState(false);
  const [modalRevokeAccess, setModalRevokeAccess] = useState(false);
  const [modalGrantAccess, setModalGrantAccess] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingAccess, setIsLoadingAccess] = useState(false);

  const handleDeleteInvitation = () => {
    if (selectedEmployee.origin !== 0) {
      // se elimina la invitacion de un empleado invitado
      return deleteInvitationEmployee_adapter(
        selectedEmployee.idEmployee,
        dispatch,
        setIsLoadingDelete,
        setModalDeleteInvitation
      );
    } else {
      // se elimina la invitacion de un empleado agregado
      return deleteEmployeeInviteAdapter(
        selectedEmployee.idEmployee,
        dispatch,
        setIsLoadingDelete,
        setModalDeleteInvitation
      );
    }
  };

  const handleRevokeAccess = async () => {
    setIsLoadingAccess(true);
    const response = await revokeAccess_adapter(selectedEmployee.idEmployee);
    if (!response.isSuccess) {
      setIsLoadingAccess(false);
      dispatch(updateNotificationMessage(response.message));
      dispatch(updateNotificationStatus(response.status));
      return;
    } else {
      setIsLoadingAccess(false);
      setModalRevokeAccess(false);
      dispatch(updateNotificationMessage(response.message));
      dispatch(updateNotificationStatus(response.status));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const handleGrantAccess = async () => {
    setIsLoadingAccess(true);
    const response = await giveAccess_adapter(selectedEmployee.idEmployee);
    if (!response.isSuccess) {
      setIsLoadingAccess(false);
      dispatch(updateNotificationMessage(response.message));
      dispatch(updateNotificationStatus(response.status));
      return;
    } else {
      setIsLoadingAccess(false);
      setModalGrantAccess(false);
      dispatch(updateNotificationMessage(response.message));
      dispatch(updateNotificationStatus(response.status));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <div className={style.containerLista}>
      {filteredEmployees?.length === 0 && (
        <div className={style.containerEmpleadoTabla}></div>
      )}
      <ul>
        {filteredEmployees?.map((employee: IEmployee) =>
          renderEmployee(
            employee,
            navigate,
            dispatch,
            resendStatus,
            setResendStatus,
            setModalDeleteInvitation,
            setModalRevokeAccess,
            setModalGrantAccess,
            setSelectedEmployee
          )
        )}
      </ul>
      <div className={style.containerPaginated}>
        {employeesState?.length !== 0 &&
          options?.length !== 0 &&
          options.length > 10 &&
          employeesState.length > 10 && (
            <PaginationWrapper
              currentPagination={currentPagination}
              setCurrentPagination={setCurrentPagination}
              options={options}
              optionsPerPage={elementsPerPage}
            />
          )}
      </div>
      <DeleteInvitationModal
        isVisible={modalDeleteInvitation}
        setIsVisible={setModalDeleteInvitation}
        employee={selectedEmployee}
        onConfirm={handleDeleteInvitation}
        isLoading={isLoadingDelete}
      />
      <RevokeAccessModal
        isVisible={modalRevokeAccess}
        setIsVisible={setModalRevokeAccess}
        employee={selectedEmployee}
        onConfirm={handleRevokeAccess}
        isLoading={isLoadingAccess}
      />
      <GrantAccessModal
        isVisible={modalGrantAccess}
        setIsVisible={setModalGrantAccess}
        employee={selectedEmployee}
        onConfirm={handleGrantAccess}
        isLoading={isLoadingAccess}
      />
    </div>
  );
};

export default EmployeeRow;
