import { STATUS_OK } from "@Models/httpsStatus";
import { IEmployee, IEmployeeService } from "@Models/interfaces/employees";
import { AppDispatch } from "@ReduxService/index";
import { modifyEmployee } from "@ReduxService/states/employeesSlice";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { getAllServicesService } from "@Services/gets";
import { editEmployeeService } from "@Services/puts";

export const getAllServicesAdapter = async () => {
  const response: IResponse = await getAllServicesService();

  if (!response.isSuccess) {
    return response;
  }

  const formattedServices: IService[] = response.data.map((service) => ({
    idService: service.idService,
    name: service.serviceName,
  }));

  return {
    status: response.status,
    message: response.message,
    data: formattedServices,
  };
};

export const updateEmployeeServicesAdapter = async (
  idEmployee: number,
  selectedEmployee,
  initialServices: IService[],
  setInitialServicesState: Function,
  selectedServices: IEmployeeService[],
  dispatch: AppDispatch,
  setVisible: Function
) => {
  const servicesToAdd = selectedServices.filter(
    (service) =>
      !initialServices.some(
        (initialService) => initialService.idService === service.idService
      )
  );
  const servicesToRemove = initialServices.filter(
    (initialService) =>
      !selectedServices.some(
        (service) => service.idService === initialService.idService
      )
  );

  const body = {
    id: idEmployee,
    newServiceIds: servicesToAdd.map((service) => service.idService),
    deleteServiceIds: servicesToRemove.map((service) => service.idService),
  };

  const response: IResponse = await editEmployeeService(body);

  if (response.isSuccess) {
    dispatch(updateNotificationMessage("Servicios actualizados exitosamente"));
    dispatch(updateNotificationStatus(STATUS_OK));

    // Update the initial services state
    setInitialServicesState((prevState) => ({
      ...prevState,
      employeeServices: {
        isSuccess: true,
        status: STATUS_OK,
        data: selectedServices,
      },
    }));

    // Close the modal
    setVisible(false);

    // Update the employee in the Redux store
    dispatch(
      modifyEmployee({
        ...selectedEmployee,
        services: selectedServices,
      })
    );
  } else {
    dispatch(
      updateNotificationMessage(
        response.message || "Error al actualizar servicios"
      )
    );
    dispatch(updateNotificationStatus(response.status));
  }

  return response;
};
