import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
export interface IListOfEmployeeCommissions {
  commisionId: number;
  commisionName: string;
  typeAmount: number;
  typeAmountDescription: string;
  amount: number;
  goal: number;
  methodId: number;
  methodDescription: string;
}

const DEFAULT_STATE = {
  listOfEmployeeCommissions: [],
};

const initialState = DEFAULT_STATE;

export const listOfEmployeeCommissionsSlice = createSlice({
  name: "listOfEmployeeCommissions",
  initialState,
  reducers: {
    updateListOfEmployeeCommissions: (
      state,
      action: PayloadAction<IListOfEmployeeCommissions[]>
    ) => {
      return {
        ...state,
        listOfEmployeeCommissions: action.payload,
      };
    },
  },
});

export default listOfEmployeeCommissionsSlice.reducer;
export const { updateListOfEmployeeCommissions } =
  listOfEmployeeCommissionsSlice.actions;
