export const handleChangeStartPeriod = (e, setSalesPeriod: Function) => {
  setSalesPeriod((prev) => ({
    ...prev,
    startPeriod: e.target.value,
  }));
};

export const handleChangeEndPeriod = (e, setSalesPeriod: Function) => {
  setSalesPeriod((prev) => ({
    ...prev,
    endPeriod: e.target.value,
  }));
};
