//@ts-ignore
import style from "./ReportStatCard.module.css";

// Components
// @ts-ignore
import { Icon } from "@viuti/recursos";

const ReportStatCard = ({ icon, title, value }) => {
  return (
    <div className={style.cardContainer}>
      <span className={style.card__symbol_and_value}>
        <Icon path={icon} color={"#937CF4"} size={25} />
        <h1 className={style.cardValue}>{value}</h1>
      </span>
      <h2 className={style.cardTitle}>{title}</h2>
    </div>
  );
};

export default ReportStatCard;
