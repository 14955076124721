import { AppDispatch } from "@ReduxService/index";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { postPayCommisionEmployee_service } from "@Services/Employee/postPayCommisionEmployee.service";

export const postPayCommisionEmployee_adapter = async (
  data: any,
  dispatch: AppDispatch
) => {
  const adaptData = {
    commisionId: data.commisionId,
    employeeId: data.employeeId,
    startDate: data.startDate,
    endDate: data.endDate,
    documentDetails: data.documentDetails.map((document: any) => ({
      documentId: document.documentId,
      documentDetailId: document.documentDetailId,
      documentDetailPackage: document.documentDetailPackage,
    })),
  };
  const response = await postPayCommisionEmployee_service(adaptData);
  dispatch(updateNotificationMessage(response.message));
  dispatch(updateNotificationStatus(response.status));
  return {
    ...response,
    data: null,
  };
};
