import { configureStore } from "@reduxjs/toolkit";

// Data reducers
import currentEmployeeReducer from "./states/currentEmployeeSlice";
import currentEmployeeStatsReducer from "./states/currentEmployeeStatsSlice";
import currentEmployeeSalesReducer from "./states/currentEmployeeSalesSlice";
import employeesReducer from "./states/employeesSlice";
import listOfEmployeeCommissionsReducer from "./states/listOfEmployeeCommissionsSlice";
// Components reducers
import notificationReducer from "./states/notificationSlice";
import commissionsToPayForEmployeeReducer from "./states/commissionsToPayForEmployeeSlice";
export const store = configureStore({
  reducer: {
    currentEmployee: currentEmployeeReducer,
    currentEmployeeStats: currentEmployeeStatsReducer,
    currentEmployeeSales: currentEmployeeSalesReducer,
    employeesList: employeesReducer,
    notificationConfig: notificationReducer,
    listOfEmployeeCommissions: listOfEmployeeCommissionsReducer,
    commissionsToPayForEmployee: commissionsToPayForEmployeeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppSelector = (state: RootState) => RootState;
