import { IEmployeeLoyalty, IEmployeeStats } from "@Models/interfaces/employees";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const DEFAULT_STATE = {
  stats: [],
  loyalty: {
    clientsServed: null,
    usualClients: null,
    loyaltyPercentage: null,
  },
};

const initialState = DEFAULT_STATE;

export const currentEmployeeStatsSlice = createSlice({
  name: "currentEmployeeStats",
  initialState,
  reducers: {
    updateEmployeeStats: (state, action: PayloadAction<IEmployeeStats[]>) => {
      return {
        ...state,
        stats: action.payload,
      };
    },
    updateLoyaltyStats: (state, action: PayloadAction<IEmployeeLoyalty>) => {
      return {
        ...state,
        loyalty: action.payload,
      };
    },
  },
});

export default currentEmployeeStatsSlice.reducer;
export const { updateEmployeeStats, updateLoyaltyStats } =
  currentEmployeeStatsSlice.actions;
