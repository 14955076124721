export const handleEditableChange = (e, setEditedFormValues: Function) => {
  const { name, value } = e.target;
  const regexOnlyNumbers = /^[0-9+]*$/;
  const regexOnlyLetters = /^[a-zA-ZáéíóúÁÉÍÓÚ\s]*$/;
  if (name === "phone") {
    // Validate that the phone number only contains numbers and a maximum of 2 "+" signs
    if (!regexOnlyNumbers.test(value)) return;
    if (value.split("+").length > 2) return;
  }

  if (name === "name" || name === "surname" || name === "alias") {
    // Validate that the name only contains letters
    if (!regexOnlyLetters.test(value)) return;
  }

  setEditedFormValues((prev) => ({
    ...prev,
    [name]: value,
  }));
};
