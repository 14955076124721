import axios from "axios";

const Error401URL = window.location.origin + "/";
const ServerErrorURL = window.location.origin + "/time-out";
const TIMEOUT = 15000;

axios.interceptors.request.use((config) => {
  config.timeout = TIMEOUT;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      localStorage.clear();
      window.location.href = Error401URL;
    }

    return Promise.reject(error);
  }
);
