import { putData, teamBaseUrl } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const putRevokeEmployeeAccess_service = async (idEmployee) => {
  const url = `${teamBaseUrl}/RevokeAccessEmployee?idEmployee=${idEmployee}`;
  const revoca_acceso_empleado = 45;
  trackUserAction(revoca_acceso_empleado);
  const successMessage = "Se ha revocado el acceso al empleado exitosamente";
  const errorMessage = "Error al revocar el acceso al empleado";
  return await putData(url, {}, successMessage, errorMessage);
};
