import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import style from "./EmployeeInfo.module.css";
import { validateEditEmployeeForm } from "./functions/validateForm";
import {
  CriticalErrorPage,
  ButtonWithoutIcon,
  Icon,
  TextInput,
  SelectInput,
  validateModuleAccess,
  DateInput,
} from "../../../../recursos";
import EditServicesModal from "@Components/ui/molecules/ServicesModal/EditServicesModal";
import SchedulesModal from "@Components/ui/molecules/SchedulesModal/SchedulesModal";
import { CameraIcon, EditIcon } from "@Models/icons";
import {
  HeaderContext,
  HeaderContextProps,
} from "@Hooks/useSitesHeaderContext";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOfEachWord,
  formatDates,
} from "@Utilities/texts";
import { handleEditableChange } from "./functions/inputHandler";
import { getEmployeeInfo } from "./functions/getEmployeeInfo";
import { useParams } from "react-router-dom";
import { handleDeleteEmployee } from "./functions/deleteEmployee";
import ConfirmModal from "@Components/ui/molecules/ConfirmModal/ConfirmModal";
import { MODIFY_EMPLOYEES_UUID } from "@Models/consts/securityAccess";
import { IEmployee } from "@Models/interfaces/employees";

const EmployeeInfo = () => {
  const { idEmployee } = useParams();
  const dispatch = useAppDispatch();
  const headerContext = useContext(HeaderContext) as HeaderContextProps;
  const hasPermissionsToEdit = validateModuleAccess(
    MODIFY_EMPLOYEES_UUID,
    false
  );

  const [openModalServicios, setOpenModalServicios] = useState(false);
  const [schedulesModalVisible, setSchedulesModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const fileInputRef = useRef(null);

  const saveFile = (e) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setEditedFormValues({
        ...editedFormValues,
        // @ts-ignore
        profilePicture: reader.result,
        profilePictureName: e.target.files[0].name,
      });
    });
    //@ts-ignore
    reader.readAsDataURL(e.target.files[0]);
  };

  //? State for the selected employee information
  const selectedEmployee = useAppSelector((state) => state.currentEmployee);

  //? State for initial data for the selected employee
  const employeeList = useAppSelector((state) => state.employeesList);
  const initialEmployeeValues = employeeList.find(
    (employee) => employee.idEmployee === Number(idEmployee)
  );

  //? Form values
  const [editedFormValues, setEditedFormValues] = useState<
    IEmployee & { documentNumberError?: string }
  >(selectedEmployee);
  const [promiseStatus, setPromiseStatus] = useState({
    employeeServices: { isSuccess: null, status: 0, data: null },
    employeeSchedules: { isSuccess: null, status: 0, data: null },
    companyRoles: { isSuccess: null, status: 0, data: [] },
  });

  const updateScheduleData = (newSchedules) => {
    setPromiseStatus({
      ...promiseStatus,
      employeeSchedules: {
        isSuccess: true,
        status: 200,
        data: newSchedules,
      },
    });
  };

  useEffect(() => {
    setEditedFormValues(selectedEmployee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee]);

  // Validate form data
  useEffect(() => {
    validateEditEmployeeForm(
      editedFormValues,
      selectedEmployee,
      headerContext,
      dispatch,
      initialEmployeeValues
    );

    if (
      selectedEmployee !== editedFormValues &&
      editedFormValues?.name?.length >= 3 &&
      editedFormValues?.name?.length <= 30 &&
      editedFormValues?.surname?.length >= 3 &&
      editedFormValues?.surname?.length <= 20 &&
      editedFormValues?.alias?.length >= 3 &&
      editedFormValues?.alias?.length <= 20 &&
      editedFormValues?.phone?.length >= 9 &&
      editedFormValues?.birthDate
    ) {
      headerContext.handleIsDisabled(false);
    } else {
      headerContext.handleIsDisabled(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedFormValues]);

  useEffect(() => {
    if (idEmployee)
      getEmployeeInfo(Number(idEmployee), setPromiseStatus, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEmployee]);

  useEffect(() => {
    headerContext.handleTextBtnChange("Guardar");
    headerContext.handleIsHidden(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageError = (event) => {
    event.target.style.display = "none";
    // Show a default image of the first letter of the name
    event.target.parentNode.innerHTML = `<div class=${
      style.circleAvatar
    }>${editedFormValues?.name?.charAt(0).toUpperCase()}</div>`;
  };

  const documentTypeOptions = useMemo(
    () => [
      { value: 1, name: "RUC" },
      { value: 2, name: "DNI" },
      { value: 3, name: "Carnet de Extranjería" },
      { value: 4, name: "Pasaporte" },
    ],
    []
  );

  const genderOptions = useMemo(
    () => [
      { value: 1, name: "Masculino" },
      { value: 2, name: "Femenino" },
      { value: 3, name: "Otro" },
    ],
    []
  );

  const handleEditableChangeOptimized = useCallback(
    (e, setEditedFormValues) => {
      const { name, value } = e.target;

      if (name === "documentNumber" || name === "documentType") {
        setEditedFormValues((prev) => {
          const newState = { ...prev };
          let error = "";

          if (name === "documentNumber") {
            // Permitir solo números para RUC y DNI
            if (
              newState.documentType === 1 ||
              newState.documentType === 2 ||
              newState.documentType === "1" ||
              newState.documentType === "2"
            ) {
              newState[name] = value.replace(/\D/g, "");
            } else {
              newState[name] = value.replace(/\s/g, "");
            }
          } else {
            newState[name] = value;
          }

          if (newState.documentType && !newState.documentNumber) {
            error = "El número de documento es obligatorio";
          } else if (
            newState.documentType === 2 ||
            newState.documentType === "2"
          ) {
            // DNI
            if (newState.documentNumber.length !== 8) {
              error = "El DNI debe tener exactamente 8 dígitos";
            }
          } else if (
            newState.documentType === 1 ||
            newState.documentType === "1"
          ) {
            // RUC
            if (newState.documentNumber.length !== 11) {
              error = "El RUC debe tener exactamente 11 dígitos";
            }
          }

          return {
            ...newState,
            documentNumberError: error,
          };
        });
      } else {
        handleEditableChange(e, setEditedFormValues);
      }
    },
    []
  );

  // Función para verificar si hay errores en el formulario
  const hasFormErrors = useCallback(() => {
    return (
      editedFormValues?.name?.length < 3 ||
      editedFormValues?.name?.length > 30 ||
      editedFormValues?.surname?.length < 3 ||
      editedFormValues?.surname?.length > 20 ||
      editedFormValues?.alias?.length < 3 ||
      editedFormValues?.alias?.length > 20 ||
      editedFormValues?.phone?.length < 9 ||
      !editedFormValues?.birthDate ||
      !editedFormValues?.documentType ||
      !editedFormValues?.documentNumber ||
      !editedFormValues?.gender ||
      !!editedFormValues.documentNumberError
    );
  }, [editedFormValues]);

  // Efecto para actualizar el estado del botón
  useEffect(() => {
    const isFormChanged =
      JSON.stringify(selectedEmployee) !== JSON.stringify(editedFormValues);
    const shouldEnableButton = isFormChanged && !hasFormErrors();
    headerContext.handleIsDisabled(!shouldEnableButton);
  }, [editedFormValues, selectedEmployee, hasFormErrors, headerContext]);

  if (
    promiseStatus.employeeServices.isSuccess === false ||
    promiseStatus.employeeSchedules.isSuccess === false ||
    promiseStatus.companyRoles.isSuccess === false
  ) {
    return (
      <div id="viuti-front-mainContent">
        <CriticalErrorPage />
      </div>
    );
  }
  return (
    <article className={style.container}>
      <section className={style.form}>
        <section className={style.basicsContainer}>
          <header className={style.headerContainer}>
            <div className={style.avatarContainer}>
              {editedFormValues?.profilePicture ? (
                <img
                  src={editedFormValues?.profilePicture}
                  alt="imagen seleccionada"
                  className={style.pickedImage}
                  onError={handleImageError}
                />
              ) : (
                <div className={style.circleAvatar}>{`${editedFormValues?.name
                  ?.charAt(0)
                  .toUpperCase()}`}</div>
              )}
              {/* <button
								className={style.cameraLayout}
								onClick={handleButtonClick}
							>
								<Icon path={CameraIcon} color={'#ffffff'} size={36} />
							</button> */}
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={saveFile}
                style={{ display: "none" }} // Hide the input
              />
            </div>
            <div className={style.nameContainer}>
              <TextInput
                label="Nombre"
                placeholder="Nombre"
                value={capitalizeFirstLetter(editedFormValues.name)}
                handleChange={(e) =>
                  handleEditableChangeOptimized(e, setEditedFormValues)
                }
                name="name"
                flex={1}
                width={"100%"}
                inputProps={{ type: "text" }}
                error={"El nombre debe tener al menos 3 caracteres y máximo 20"}
                touched={
                  editedFormValues?.name?.length < 3 ||
                  editedFormValues?.name?.length > 30
                }
                required
                disabled={!hasPermissionsToEdit}
              />
              <TextInput
                label="Apellidos"
                placeholder="Apellido"
                value={capitalizeFirstLetter(editedFormValues.surname)}
                handleChange={(e) =>
                  handleEditableChangeOptimized(e, setEditedFormValues)
                }
                name="surname"
                flex={1}
                width={"100%"}
                inputProps={{ type: "text" }}
                error={
                  "El apellido debe tener al menos 3 caracteres y máximo 20"
                }
                touched={
                  editedFormValues?.surname?.length < 3 ||
                  editedFormValues?.surname?.length > 20
                }
                required
                disabled={!hasPermissionsToEdit}
              />
            </div>
          </header>
          <section className={style.containerServiciobutton}>
            <span className={style.servicesContainer}>
              <h3>Servicios</h3>
              <button
                className={style.modifyBubble}
                onClick={() =>
                  validateModuleAccess(MODIFY_EMPLOYEES_UUID) &&
                  setOpenModalServicios(true)
                }
              >
                <img src={EditIcon} alt="lapiz" className={style.pencilIcon} />
              </button>
            </span>
            <p className={style.p}>
              Agrega o modifica los servicios que realiza el empleado
              <button
                className={style.span}
                onClick={() =>
                  validateModuleAccess(MODIFY_EMPLOYEES_UUID) &&
                  setOpenModalServicios(true)
                }
              >
                dando clic aquí.
              </button>
            </p>
          </section>
          <section className={style.containerServiciobutton}>
            <span className={style.servicesContainer}>
              <h3>Horarios</h3>
              <button
                className={style.modifyBubble}
                onClick={() =>
                  validateModuleAccess(MODIFY_EMPLOYEES_UUID) &&
                  setSchedulesModalVisible(true)
                }
              >
                <img src={EditIcon} alt="lapiz" className={style.pencilIcon} />
              </button>
            </span>
            <p className={style.p}>
              Agrega o modifica los horarios que realiza el empleado
              <button
                onClick={() =>
                  validateModuleAccess(MODIFY_EMPLOYEES_UUID) &&
                  setSchedulesModalVisible(true)
                }
                className={style.span}
              >
                dando clic aquí.
              </button>
            </p>
          </section>
          {/* {isMyEmail ? ( */}
          <ButtonWithoutIcon
            isPrimary={false}
            textBttn={"Eliminar empleado"}
            handleClick={() => setIsDeleteModalVisible(true)}
            disabled={!hasPermissionsToEdit}
          />
          {/* ) : null} */}
        </section>
        <section className={style.employeeDataContainer}>
          <div className={style.column}>
            <TextInput
              label="Alias"
              placeholder="Alias"
              name="alias"
              value={capitalizeFirstLetter(editedFormValues.alias)}
              handleChange={(e) =>
                handleEditableChangeOptimized(e, setEditedFormValues)
              }
              inputProps={{ type: "text" }}
              error={
                editedFormValues.alias === ""
                  ? "El alias es obligatorio"
                  : "El alias debe tener al menos 3 caracteres y máximo 20"
              }
              touched={
                editedFormValues.alias === "" ||
                editedFormValues?.alias?.length < 3 ||
                editedFormValues?.alias?.length > 20
              }
              flex={1}
              required
              disabled={!hasPermissionsToEdit}
            />
            <TextInput
              label="Correo"
              placeholder="Correo"
              value={editedFormValues.email}
              flex={1}
              disabled
            />
            <SelectInput
              label="Rol"
              placeholder={"Administrador"}
              value={
                selectedEmployee.isAdmin ? "" : editedFormValues.position || ""
              }
              items={promiseStatus.companyRoles.data}
              handleChange={(e) =>
                handleEditableChangeOptimized(e, setEditedFormValues)
              }
              name="position"
              disabled={selectedEmployee.isAdmin || !hasPermissionsToEdit}
            />
            <SelectInput
              label="Tipo de Documento"
              placeholder="Seleccione tipo de documento"
              value={editedFormValues.documentType}
              items={documentTypeOptions}
              handleChange={(e) =>
                handleEditableChangeOptimized(e, setEditedFormValues)
              }
              name="documentType"
              disabled={!hasPermissionsToEdit}
              required
              error={
                editedFormValues.documentType === ""
                  ? "El tipo de documento es obligatorio"
                  : ""
              }
              touched={editedFormValues.documentType === ""}
            />
          </div>
          <div className={style.column}>
            <DateInput
              label="Fecha de Nacimiento"
              inputProps={{
                value: editedFormValues?.birthDate,
                onChange: (e) =>
                  handleEditableChangeOptimized(e, setEditedFormValues),
                name: "birthDate",
              }}
              required
              error={
                editedFormValues?.birthDate === ""
                  ? "La fecha de nacimiento es obligatoria"
                  : ""
              }
              touched={editedFormValues?.birthDate === ""}
              disabled={!hasPermissionsToEdit}
            />
            <TextInput
              label="Teléfono"
              placeholder="Teléfono"
              value={editedFormValues.phone}
              name="phone"
              handleChange={(e) =>
                handleEditableChangeOptimized(e, setEditedFormValues)
              }
              inputProps={{ maxLength: 12, type: "tel" }}
              error={
                editedFormValues.phone === ""
                  ? "El teléfono es obligatorio"
                  : "El teléfono debe tener al menos 9 caracteres"
              }
              touched={
                editedFormValues.phone === "" ||
                editedFormValues?.phone?.length < 9
              }
              disabled={!hasPermissionsToEdit}
            />
            <SelectInput
              label="Sexo"
              placeholder="Seleccione género"
              value={editedFormValues.gender}
              items={genderOptions}
              handleChange={(e) =>
                handleEditableChangeOptimized(e, setEditedFormValues)
              }
              name="gender"
              required
              disabled={!hasPermissionsToEdit}
              error={
                editedFormValues.gender === "" ? "El género es obligatorio" : ""
              }
              touched={editedFormValues.gender === ""}
            />

            <TextInput
              label="Número de Documento"
              placeholder="Ingrese número de documento"
              value={editedFormValues.documentNumber}
              name="documentNumber"
              handleChange={(e) =>
                handleEditableChangeOptimized(e, setEditedFormValues)
              }
              inputProps={{
                type: "text",
                maxLength:
                  editedFormValues.documentType === 2
                    ? 8
                    : editedFormValues.documentType === 1
                    ? 11
                    : undefined,
                pattern: "[0-9]*", // Solo permite números
              }}
              error={
                editedFormValues.documentNumberError ||
                (editedFormValues.documentType &&
                !editedFormValues.documentNumber
                  ? "El número de documento es obligatorio"
                  : "")
              }
              touched={
                !!editedFormValues.documentNumberError ||
                (editedFormValues.documentType &&
                  !editedFormValues.documentNumber)
              }
              disabled={!hasPermissionsToEdit || !editedFormValues.documentType}
              required
            />
          </div>
        </section>
      </section>
      <EditServicesModal
        initialServicesState={promiseStatus.employeeServices.data}
        setInitialServicesState={setPromiseStatus}
        visible={openModalServicios}
        setVisible={setOpenModalServicios}
        idEmployee={editedFormValues.idEmployee}
      />
      <SchedulesModal
        initialSchedulesState={promiseStatus.employeeSchedules.data}
        setInitialSchedulesState={updateScheduleData}
        visible={schedulesModalVisible}
        setVisible={setSchedulesModalVisible}
        idEmployee={editedFormValues.idEmployee}
      />
      <ConfirmModal
        title={"Eliminar empleado"}
        message={`Estás a punto de eliminar ${capitalizeFirstLetterOfEachWord(
          selectedEmployee.name
        )} ${capitalizeFirstLetterOfEachWord(
          selectedEmployee.surname
        )} de tu empresa, ¿estás seguro?`}
        buttonLbl={"Eliminar"}
        controller={{
          visible: isDeleteModalVisible,
          setVisible: setIsDeleteModalVisible,
        }}
        dispatch={dispatch}
        confirmAction={() =>
          handleDeleteEmployee(
            Number(idEmployee),
            dispatch,
            setIsDeleteModalVisible
          )
        }
      />
    </article>
  );
};

export default EmployeeInfo;
