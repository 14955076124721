// Styles
//@ts-ignore
import style from "./SalesReportTable.module.css";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";

// Models
import { IEmployeeSaleServed } from "@Models/interfaces/employees";

// Utils
import { formatToSoles } from "@Utilities/texts";
//@ts-ignore
import { TableMobile } from "@viuti/recursos";
import { adaptDataTableMobile } from "./adaptDataTableMobile/adaptDataTableMobile";

interface Props {
  tableContent: IEmployeeSaleServed[];
  status: number;
}

const SalesReportTable = ({ tableContent, status }: Props) => {
  const screenWidth = useScreenWidth();
  return screenWidth >= 900 ? (
    <div className={style.tableContainer}>
      <table
        className={style.tableContent}
        style={{ tableLayout: "fixed", width: "100%" }}
      >
        <colgroup>
          <col style={{ width: "138px" }} />
          <col style={{ width: "200px" }} />
          <col style={{ width: "90px" }} />
          <col style={{ width: "96px" }} />
          <col style={{ width: "100px" }} />
          <col style={{ width: "107px" }} />
          <col style={{ width: "124px" }} />
        </colgroup>
        <thead className={style.headerContainer}>
          <tr>
            <th className={style.headerCell}>Nro Documento</th>
            <th className={`${style.headerCell} ${style.bodyCellDate}`}>
              Fecha
            </th>
            <th className={style.headerCell}>Servicio</th>
            <th className={style.headerCell}>Producto</th>
            <th className={style.headerCell}>Monto S/.</th>
            <th className={style.headerCell}>Descuento</th>
            <th className={style.headerCell}>Total cobrado</th>
          </tr>
        </thead>
        <tbody>
          {tableContent.length > 0 &&
            tableContent.map((sale) => (
              <tr key={sale.commandNumber}>
                <td className={style.bodyCell}>
                  {sale.commandNumber || "No identificado"}
                </td>
                <td className={`${style.bodyCell} ${style.bodyCellDate}`}>
                  {sale.date}
                </td>
                <td className={style.bodyCell}>{sale.service}</td>
                <td className={style.bodyCell}>{sale.productId}</td>
                <td className={style.bodyCell}>{sale.productPrice}</td>
                <td className={style.bodyCell}>{sale.discount}</td>
                <td className={style.bodyCell}>
                  {formatToSoles(sale.totalSale)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {status === 200 ? (
        tableContent.length === 0 && (
          <div className={style.statusmessage}>
            <p>No se han encontrado registros...</p>
          </div>
        )
      ) : status === null ? (
        <div className={style.statusmessage}>
          <p>Seleccione un rango de fechas.</p>
        </div>
      ) : status === 0 ? (
        <div className={style.statusmessage}>
          <p>Cargando...</p>
        </div>
      ) : (
        <div className={style.statusmessage}>
          <p>Ha ocurrido un error al cargar los datos.</p>
        </div>
      )}
    </div>
  ) : (
    <TableMobile
      isLoading={false}
      dataTable={tableContent.map((data, index) =>
        adaptDataTableMobile(data, screenWidth, index)
      )}
    />
  );
};

export default SalesReportTable;
