import { postData, teamBaseUrl } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const postCreateEmployees_service = async (data) => {
  const url = `${teamBaseUrl}/CreateEmployeeWithOutAccess`;
  const agrega_empleados = 49;
  trackUserAction(agrega_empleados);
  const successMessage = "Se ha guardado un nuevo empleado exitosamente";
  const errorMessage = "Error al guardar el empleado";
  return await postData(url, data, successMessage, errorMessage);
};
