import { IEmployeeSaleServed } from "@Models/interfaces/employees";
import { formatToSoles } from "@Utilities/texts";

export const adaptDataTableMobile = (
  data: IEmployeeSaleServed,
  screenWidth: number,
  index
) => {
  let titles = [
    <p>{data.commandNumber || "No identificado"}</p>,
    <p>{data.date}</p>,
    <p>{formatToSoles(data.totalSale)}</p>,
  ];
  if (screenWidth <= 500) {
    titles = [
      <p>{data.commandNumber || "No identificado"}</p>,
      <p>{formatToSoles(data.totalSale)}</p>,
    ];
  }

  return {
    id: index,
    header: titles,
    body: [
      {
        item1: <p>Nro Documento</p>,
        item2: <p>{data.commandNumber || "No identificado"}</p>,
      },
      {
        item1: <p>Fecha</p>,
        item2: <p>{data.date}</p>,
      },
      {
        item1: <p>Servicio</p>,
        item2: <p>{data.service}</p>,
      },
      {
        item1: <p>Producto</p>,
        item2: <p>{data.productId}</p>,
      },
      {
        item1: <p>Monto S/.</p>,
        item2: <p>{data.productPrice}</p>,
      },
      {
        item1: <p>Descuento</p>,
        item2: <p>{data.discount}</p>,
      },
      {
        item1: <p>Total cobrado</p>,
        item2: <p>{formatToSoles(data.totalSale)}</p>,
      },
    ],
  };
};
