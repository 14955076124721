// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AMASZ5pd9zh2Z2tuk0x5 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.qd4WdCNzXM85LVIjQTjd {
  text-align: center;
  color: #4d4d4d;
  font: 700 18px/24px "Mulish", sans-serif;
  margin: 10px 0px 15px 0px;
}

.V0ik1pSQcRX0dZkPhSDg {
  text-align: center;
  color: #4d4d4d;
  font: 500 14px/24px "Mulish", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/ErrorView/ErrorView.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,wCAAwC;EACxC,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,wCAAwC;AAC1C","sourcesContent":[".errorViewContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.primaryErrorMsg {\n  text-align: center;\n  color: #4d4d4d;\n  font: 700 18px/24px \"Mulish\", sans-serif;\n  margin: 10px 0px 15px 0px;\n}\n\n.secondaryErrorMsg {\n  text-align: center;\n  color: #4d4d4d;\n  font: 500 14px/24px \"Mulish\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorViewContainer": `AMASZ5pd9zh2Z2tuk0x5`,
	"primaryErrorMsg": `qd4WdCNzXM85LVIjQTjd`,
	"secondaryErrorMsg": `V0ik1pSQcRX0dZkPhSDg`
};
export default ___CSS_LOADER_EXPORT___;
