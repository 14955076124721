// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c6VMbWjd_SDn4DQcdOuh {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 16px;
  gap: 8px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;

  @media screen and (width < 470px) {
    gap: 10px;
  }
}

.Ru2frQ3x8vB23qpRiMW1,
.aG6DPtud0r5Ormfbbqgy {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.875rem;
}

.aG6DPtud0r5Ormfbbqgy {
  padding: 8px;
  background-color: #e4fde8;
  border-radius: 6px;
  gap: 0.6rem;
}

.gF7w5mxYtr0hKZ_8qedN {
  background-color: #fff0f2;
}

.cV0ZyuR7G0uSBfyBXPZK {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #45348e;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/AvailableSeats/AvailableSeats.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;;EAElB;IACE,SAAS;EACX;AACF;;AAEA;;EAEE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  padding: 12px 16px;\n  gap: 8px;\n  width: 100%;\n  background-color: #ffffff;\n  border: 1px solid #e0e0e0;\n  border-radius: 8px;\n\n  @media screen and (width < 470px) {\n    gap: 10px;\n  }\n}\n\n.seats_container,\n.available_seats_container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 0.875rem;\n}\n\n.available_seats_container {\n  padding: 8px;\n  background-color: #e4fde8;\n  border-radius: 6px;\n  gap: 0.6rem;\n}\n\n.exceeded {\n  background-color: #fff0f2;\n}\n\n.users_icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  background-color: #f0f0f0;\n  color: #45348e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `c6VMbWjd_SDn4DQcdOuh`,
	"seats_container": `Ru2frQ3x8vB23qpRiMW1`,
	"available_seats_container": `aG6DPtud0r5Ormfbbqgy`,
	"exceeded": `gF7w5mxYtr0hKZ_8qedN`,
	"users_icon": `cV0ZyuR7G0uSBfyBXPZK`
};
export default ___CSS_LOADER_EXPORT___;
