import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./InviteEmployees.module.css";
import {
  InfoNote,
  HeaderNavigation,
  TextInput,
  SelectInput,
  getUserPermissions,
  validateModuleAccess,
} from "../../recursos";
import { goToMainView } from "../../utilities/navigate";
import { isEmailValid } from "./functions/validateEmail";
import { handleFieldChange, handleResetForm } from "./functions/handleForm";
import { sendEmployeeInviteAdapter } from "@Adapters/employeesInvites.adapter";
import { useAppDispatch } from "@Hooks/store";
import { getPositionsAdapter } from "@Adapters/getPositions/getPositions.adapter";
import { HeaderContext } from "@Hooks/useSitesHeaderContext";
import ConfirmModal from "@Components/ui/molecules/ConfirmModal/ConfirmModal";
import { INVITE_EMPLOYEES_UUID } from "@Models/consts/securityAccess";
import { ROUTE_EMPLOYEES } from "@Models/routes";
import { getApiSites } from "@Services/gets";

const InviteEmployees = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const headerContext: any = useContext(HeaderContext);
  const userPermissions = getUserPermissions();

  const [editedFormValues, setEditedFormValues] = useState({
    email: "",
    position: "",
    lounge: "",
  });

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const handleEmployeeExist = () => {
    setIsDeleteModalVisible(true);
  };

  useEffect(() => {
    headerContext.handleTextBtnChange("Enviar");
    headerContext.handleIsHidden(false);
    headerContext.handleIsDisabled(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [apiPositions, setApiPositions] = useState([]);
  const [apiSites, setApiSites] = useState([]);

  const storedLocal = JSON.parse(localStorage.getItem("selectedLocal"));
  const selectedPosition = apiPositions.find(
    (position) => position.value === editedFormValues.position
  );

  const sitesHeaderProps = {
    textBttn: headerContext.textBttn,
    handleClick: () =>
      sendEmployeeInviteAdapter(
        editedFormValues,
        () => handleResetForm(setEditedFormValues),
        selectedPosition.name,
        dispatch,
        headerContext,
        handleEmployeeExist
      ),
    isDisabled: headerContext.isDisabled,
    isHidden: headerContext.isHidden,
    isPrimary: true,
    isLoading: headerContext.isLoading,
  };

  /* The effect fetch the db positions saved */
  useEffect(() => {
    getPositionsAdapter(dispatch).then((res) => {
      setApiPositions(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* The effect is triggered whenever the `editedFormValues` state changes, and checks if there are empty values in the form to enable or disable the submit button. */
  useEffect(() => {
    const hasErrors = isEmailValid(editedFormValues.email) || !selectedPosition;

    const validation = Object.values(editedFormValues).some(
      (value) => value === ""
    );

    if (!validation && hasErrors) {
      headerContext.handleIsDisabled(false);
    } else {
      headerContext.handleIsDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedFormValues]);

  const headerProps = {
    title: "Invitar miembro",
    previousAction: () => goToMainView(navigate),
    previousActionMovement: "back",
    buttonProps: sitesHeaderProps,
  };

  useEffect(() => {
    getApiSites().then((res) => {
      if (res.isSuccess) {
        const formattedSites = res.data.map((site) => ({
          value: site.lounge.toString(),
          name: site.nameLounge,
        }));
        setApiSites(formattedSites);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localOptions =
    apiSites.length > 0
      ? apiSites
      : storedLocal
      ? [
          {
            value: storedLocal.id?.toString() || "",
            name: storedLocal.nombre || "",
          },
        ]
      : [];

  return (
    <div id="viuti-front-mainContent">
      <div className={style.containerMainView}>
        <HeaderNavigation {...headerProps} />
        <h2 className={style.viewTitleContent}>Datos del nuevo miembro</h2>
        <h3>
          La persona que reciba la invitación tendrá acceso a registrar su
          información.
        </h3>
        {userPermissions?.account?.isAdmin && (
          <InfoNote type="info">
            <p>
              Como administrador, puedes invitar a cualquier persona a tu
              equipo. Si la persona ya está registrada en otra empresa o local,
              se enviará una nueva invitación. Puedes acceder a la configuración
              de roles en la pestaña de Seguridad o haciendo{" "}
              <a
                href={`${window.location.origin}/roles`}
                target="blank"
                className={style.info_note__link}
              >
                clic aquí
              </a>
              .
            </p>
          </InfoNote>
        )}
        <div className={style.form__container}>
          <div className={style.form__input}>
            <TextInput
              label="Correo electrónico"
              placeholder="correo@correo.gmail"
              value={editedFormValues.email}
              error={
                editedFormValues.email && !isEmailValid(editedFormValues.email)
                  ? "El correo no es válido"
                  : ""
              }
              touched={editedFormValues.email !== ""}
              handleChange={(e) =>
                handleFieldChange(e, editedFormValues, setEditedFormValues)
              }
              name="email"
              inputProps={{
                type: "email",
              }}
            />
          </div>
          <SelectInput
            label="Rol"
            value={editedFormValues.position}
            items={apiPositions}
            handleChange={(e) =>
              handleFieldChange(e, editedFormValues, setEditedFormValues)
            }
            name="position"
          />
          <SelectInput
            label="Local"
            value={editedFormValues.lounge}
            items={localOptions}
            handleChange={(e) =>
              handleFieldChange(e, editedFormValues, setEditedFormValues)
            }
            name="lounge"
          />
        </div>
      </div>
      <ConfirmModal
        title={"Empleado existente"}
        message={`El correo electrónico ${editedFormValues.email} ya está registrado en otra empresa/local, ¿desea enviar su invitación nuevamente?`}
        buttonLbl={"Enviar"}
        controller={{
          visible: isDeleteModalVisible,
          setVisible: setIsDeleteModalVisible,
        }}
        confirmAction={async () => {
          const response = await sendEmployeeInviteAdapter(
            editedFormValues,
            () => handleResetForm(setEditedFormValues),
            selectedPosition.name,
            dispatch,
            headerContext,
            handleEmployeeExist
          );

          return response;
        }}
        dispatch={dispatch}
      />
    </div>
  );
};

export default InviteEmployees;
