// /CommissionEmployee/GetCommisionPaymentHistory?employeeId=4204&page=1&commisionId=70

import { employeeBaseUrl, ApiResponse, getData } from "@Services/constServices";

export const getHistoryEmployeeCommissions_service = async (
  employeeId: number,
  page: number,
  commisionId: number
): Promise<ApiResponse> => {
  const url = `${employeeBaseUrl}CommissionEmployee/GetCommisionPaymentHistory?employeeId=${employeeId}&page=${page}&commisionId=${commisionId}`;
  const successMessage =
    "Se ha obtenido el historial de pagos de comisiones exitosamente";
  const errorMessage =
    "Error al obtener el historial de pagos de comisiones, intente nuevamente";
  return await getData(url, successMessage, errorMessage);
};
