import React from "react";

// Styles
//@ts-ignore
import style from "./StatsCard.module.css";

// Models
import { CircleUserIcon, CoinsIcon } from "@Models/icons";
import { IEmployeeStats } from "@Models/interfaces/employees";

// Utilities
import { formatToSoles } from "@Utilities/texts";

export const StatsCard = ({ stats }: { stats: IEmployeeStats }) => {
  return (
    <div className={style.cardContainer}>
      <h1 className={style.cardTitle}>
        {stats.period === "Diaria" ? "Hoy" : stats.period}
      </h1>
      <div className={style.cardContent}>
        <div className={style.statsContainer}>
          <div className={style.salesContainer}>
            <div className={style.salesContent}>
              <img className={style.monedasIcon} src={CoinsIcon} alt="dinero" />
              <h1 className={style.salesTotalValue}>
                {formatToSoles(stats.totalSales)}
              </h1>
            </div>
            <span className={style.totalSalesTitle}>Total de Ventas</span>
          </div>
          <div className={style.statsDivisor}></div>
          <div className={style.servicesProductsContainer}>
            <div className={style.servicesContainer}>
              <span className={style.statLabel}>Servicios</span>
              <span className={style.statValue}>
                {formatToSoles(stats.totalServices)}
              </span>
            </div>
            <div className={style.productsContainer}>
              <span className={style.statLabel}>Productos</span>
              <span className={style.statValue}>
                {formatToSoles(stats.totalProducts)}
              </span>
            </div>
          </div>
        </div>
        <div className={style.clientsContainer}>
          <img className={style.icon} src={CircleUserIcon} alt="User" />
          <div className={style.clientsContent}>
            <span className={style.clientsTotalValue}>
              {stats.clientsServed}
            </span>
            <span className={style.totalClientsTitle}>
              Atenciones realizadas
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
