import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Styles
import style from "./editEmployees.module.css";

// Views
import EmployeeInfoView from "./views/EmployeeInfo/EmployeeInfo";
import EmployeeStatsView from "./views/EmployeeStats/EmployeeStats";
import EmployeeSalesView from "./views/EmployeeSales/EmployeeSales";

// Components
import {
  CriticalErrorPage,
  HeaderNavigation,
  TabNavigation,
  validateModuleAccess,
} from "../../recursos";

// Utilities
import { goToMainView } from "@Utilities/navigate";
import { capitalizeFirstLetterOfEachWord } from "@Utilities/texts";

// Models
import { LoadingPulseIcon } from "@Models/icons";

// Hooks
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  HeaderContext,
  HeaderContextProps,
} from "@Hooks/useSitesHeaderContext";
import { getEmployeeData } from "./functions/getEmployeeData";
import ErrorView from "@Components/ui/molecules/ErrorView/ErrorView";
import EmployeeCommissions from "./views/EmployeeCommissions/EmployeeCommissions";

const EditEmployees = () => {
  const { idEmployee } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //? State for the selected employee information
  const selectedEmployee = useAppSelector((state) => state.currentEmployee);

  const headerContext = useContext(HeaderContext) as HeaderContextProps;

  const sitesHeaderProps = {
    textBttn: headerContext.textBttn,
    handleClick: headerContext.clickFunction,
    isDisabled: headerContext.isDisabled || headerContext.isLoading,
    isHidden: headerContext.isHidden,
    isPrimary: true,
    isLoading: headerContext.isLoading,
  };

  const [promiseStatus, setPromiseStatus] = useState({
    employeeInfo: { status: 0, data: null },
    employeeStats: { status: 0, data: null },
    employeeLoyaltyData: { status: 0, data: null },
  });

  useEffect(() => {
    if (promiseStatus.employeeInfo.status !== 200) {
      getEmployeeData(Number(idEmployee), setPromiseStatus, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEmployee]);

  const headerProps = {
    title:
      promiseStatus.employeeInfo.status === 0
        ? "Cargando..."
        : `${capitalizeFirstLetterOfEachWord(
            `${selectedEmployee?.name || "Empleado"} ${
              selectedEmployee?.surname || ""
            }`
          )}`,
    previousAction: () => goToMainView(navigate),
    previousActionMovement: "back",
    buttonProps: {
      ...sitesHeaderProps,
      isPrimary: true,
    },
  };

  const generateView = (
    name: string,
    status: number,
    Component: React.FC<any>
  ) => {
    if (status === 200) {
      return <Component />;
    } else {
      return <ErrorView>No pudimos obtener {name} del empleado.</ErrorView>;
    }
  };

  const hasCommissionsAccess = validateModuleAccess(
    "fc747aa5-5170-4fee-bbac-da9845fd43db",
    false
  );

  const navigationViews = [
    {
      name: "Datos personales",
      component: generateView(
        "la información",
        promiseStatus.employeeInfo.status,
        EmployeeInfoView
      ),
    },
    {
      name: "Estadísticas",
      component: generateView(
        "las estadísticas",
        promiseStatus.employeeStats.status,
        EmployeeStatsView
      ),
    },
    {
      name: "Reporte de ventas",
      component: generateView(
        "el reporte de ventas",
        promiseStatus.employeeLoyaltyData.status,
        EmployeeSalesView
      ),
    },
    {
      name: "Comisiones",
      component: generateView(
        "las comisiones",
        promiseStatus.employeeLoyaltyData.status,
        EmployeeCommissions
      ),
    },
  ];

  // if (!hasCommissionsAccess) {
  //   navigationViews.pop();
  // }

  if (
    promiseStatus.employeeStats.status === 500 ||
    promiseStatus.employeeLoyaltyData.status === 500 ||
    promiseStatus.employeeInfo.status === 500
  ) {
    return (
      <div id="viuti-front-mainContent">
        <CriticalErrorPage />
      </div>
    );
  }

  return (
    <div id="viuti-front-mainContent">
      <div className={style.container}>
        {promiseStatus.employeeStats.status === 0 ||
        promiseStatus.employeeLoyaltyData.status === 0 ||
        promiseStatus.employeeInfo.status === 0 ? (
          <div className={style.loadingContainer}>
            <img src={LoadingPulseIcon} alt="Cargando..." />
          </div>
        ) : (
          <>
            <HeaderNavigation {...headerProps} />
            <TabNavigation views={navigationViews} />
          </>
        )}
      </div>
    </div>
  );
};

export default EditEmployees;
