import { GW_URL } from "./constServices";

export const baseUrl = `${GW_URL}/empleados/v1`;
export const accountBaseUrl = `${GW_URL}/cuenta/v1`;

const tokenLocal = localStorage.getItem("access_token");

export const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenLocal}`,
  },
};
