import { ROUTE_EMPLOYEES } from "../models/routes";

export const goToInviteEmployee = (navigate) => {
  navigate(ROUTE_EMPLOYEES.ROUTE_INVITE_EMPLOYEES);
};

export const goToMainView = (navigate) => {
  navigate(ROUTE_EMPLOYEES.ROUTE_MAIN_VIEW);
};

export const goToAddEmployee = (navigate) => {
  navigate(ROUTE_EMPLOYEES.ROUTE_ADD_EMPLOYEES);
};
