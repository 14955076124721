import { editEmployeeAdapter } from "@Adapters/editEmployees/editEmployees.adapter";
import { HeaderContextProps } from "@Hooks/useSitesHeaderContext";
import { AppDispatch } from "@ReduxService/index";

export const validateEditEmployeeForm = (
  values: any,
  initialValues: any,
  headerContext: HeaderContextProps,
  dispatch: AppDispatch,
  employeeInitialValues
) => {
  let changedValues = Object.keys(values).reduce((acc, key) => {
    if (values[key] !== initialValues[key]) {
      acc[key] = values[key];
    }
    return acc;
  }, {});

  // @ts-ignore
  changedValues.idEmployee = values.idEmployee;

  const hasFieldEmpty = Object.values(changedValues).some((value) => {
    return value === "";
  });

  const isNameFieldValid = values?.name?.length > 2 && values.name?.length < 20;
  const isSurnameFieldValid =
    values?.surname?.length > 2 && values.surname?.length < 20;
  const isAliasFieldValid =
    values?.alias?.length > 2 && values.alias?.length < 20;
  const isPhoneFieldValid = values?.phone?.length > 8;

  const isDocumentTypeValid = values.documentType !== "";
  const isDocumentNumberValid = values.documentNumber !== "";
  const isGenderValid = values.gender !== "";

  if (
    isNameFieldValid &&
    isSurnameFieldValid &&
    isAliasFieldValid &&
    isPhoneFieldValid &&
    isDocumentTypeValid &&
    isDocumentNumberValid &&
    isGenderValid
  ) {
    headerContext.handleClickChange(async () => {
      await editEmployeeAdapter(
        changedValues,
        dispatch,
        employeeInitialValues,
        headerContext
      );
      return true;
    });
  } else {
    headerContext.handleIsDisabled(true);
    headerContext.handleClickChange(null);
  }
};
