export const initialSchedulesState = [
  {
    idDay: 0,
    day: "Lunes",
    scheduleList: [
      {
        startTime: "09:00 AM",
        endTime: "05:00 PM",
        idSchedule: null,
        isAvailable: false,
      },
    ],
  },
  {
    idDay: 1,
    day: "Martes",
    scheduleList: [
      {
        startTime: "09:00 AM",
        endTime: "05:00 PM",
        idSchedule: null,
        isAvailable: false,
      },
    ],
  },
  {
    idDay: 2,
    day: "Miércoles",
    scheduleList: [
      {
        startTime: "09:00 AM",
        endTime: "05:00 PM",
        idSchedule: null,
        isAvailable: false,
      },
    ],
  },
  {
    idDay: 3,
    day: "Jueves",
    scheduleList: [
      {
        startTime: "09:00 AM",
        endTime: "05:00 PM",
        idSchedule: null,
        isAvailable: false,
      },
    ],
  },
  {
    idDay: 4,
    day: "Viernes",
    scheduleList: [
      {
        startTime: "09:00 AM",
        endTime: "05:00 PM",
        idSchedule: null,
        isAvailable: false,
      },
    ],
  },
  {
    idDay: 5,
    day: "Sábado",
    scheduleList: [
      {
        startTime: "09:00 AM",
        endTime: "05:00 PM",
        idSchedule: null,
        isAvailable: false,
      },
    ],
  },
  {
    idDay: 6,
    day: "Domingo",
    scheduleList: [
      {
        startTime: "09:00 AM",
        endTime: "05:00 PM",
        idSchedule: null,
        isAvailable: false,
      },
    ],
  },
];
