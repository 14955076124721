import { goToMainView } from "@Utilities/navigate";
import style from "./AddMember.module.css";
import { useNavigate } from "react-router-dom";
import {
  HeaderNavigation,
  FormAddMore,
  // @ts-ignore
} from "@viuti/recursos";
import { useState, useEffect } from "react";
import { FormAddMember } from "@Components/ui/organism/FormAddMember/FormAddMember";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { useAppDispatch } from "@Hooks/store";
import { postCreateEmployees_adapter } from "@Adapters/postEmployees/postCreateEmployees.adapter";

const DEFAULT_STATE = {
  documentType: 2,
  document: "",
  sex: 0,
  dateOfBirth: "",
  name: "",
  lastName: "",
  email: "",
  telephoneNumber: "",
  alias: "",
  rol: "",
  uuid: 0,
  services: [],
  schedules: [],
};

export const AddMember = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form, setForm] = useState([{ ...DEFAULT_STATE, uuid: 0 }]);
  const [uuid, setUuid] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSearchingReniec, setIsSearchingReniec] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleDelete = (uuidToDelete) => {
    if (form.length === 1) return;
    setErrorMessage("");
    setForm((prev) => {
      return prev.filter((item) => item.uuid !== uuidToDelete);
    });
  };

  const [childrens, setChildrens] = useState([
    <FormAddMember
      key={0}
      uuid={0}
      setForm={setForm}
      form={form}
      onDelete={handleDelete}
      disableDelete={true}
      setIsSearchingReniec={setIsSearchingReniec}
    />,
  ]);

  const headerProps = {
    title: "Agregar empleados",
    previousAction: () => goToMainView(navigate),
    previousActionMovement: "back",
    buttonProps: {
      textBttn: "Confirmar",
      handleClick: async () => {
        setIsSaving(true);

        const removeAmPm = (time) => time.replace(/(AM|PM)/gi, "").trim();

        const adaptedForm = form.map((item) => ({
          documentType: item.documentType,
          document: item.document,
          sex: item.sex,
          dateOfBirth: item.dateOfBirth,
          name: item.name,
          lastName: item.lastName,
          email: item.email,
          alias: item.alias,
          rolId: Number(item.rol),
          phone: String(item.telephoneNumber),
          service: item.services.map((service) => service.id),
          employeeHours: item.schedules
            .filter((schedule) =>
              schedule.scheduleList.some((slot) => slot.isAvailable)
            )
            .flatMap((schedule) =>
              schedule.scheduleList
                .filter((slot) => slot.isAvailable)
                .map((slot) => ({
                  dayId: Number(schedule.idDay) + 1,
                  startHour: removeAmPm(slot.startTime),
                  endHour: removeAmPm(slot.endTime),
                }))
            ),
        }));

        const response = await postCreateEmployees_adapter(adaptedForm);
        if (!response.isSuccess) {
          setIsSaving(false);
          dispatch(updateNotificationMessage(response.message));
          dispatch(updateNotificationStatus(response.status));
          return;
        } else {
          setIsSaving(true);
          dispatch(updateNotificationMessage(response.message));
          dispatch(updateNotificationStatus(response.status));
          goToMainView(navigate);
        }
        setIsSaving(false);
      },
      isDisabled:
        isButtonDisabled ||
        isSaving ||
        errorMessage !== "" ||
        isSearchingReniec,
      isHidden: false,
      isPrimary: true,
      isLoading: isSaving,
    },
  };

  const handleAddMore = () => {
    const lastForm = form[form.length - 1];
    const missingFields = [];

    // Validar cada campo y agregar al array si está incompleto
    if (!lastForm.name.trim()) {
      missingFields.push("nombre");
    }
    if (!lastForm.lastName.trim()) {
      missingFields.push("apellido");
    }
    if (!lastForm.alias.trim()) {
      missingFields.push("alias");
    }
    if (lastForm.telephoneNumber.length !== 9) {
      missingFields.push("teléfono");
    }
    if (!lastForm.email || !isEmailValid(lastForm.email)) {
      missingFields.push("correo");
    }
    if (!lastForm.rol) {
      missingFields.push("rol");
    }

    // Si hay campos faltantes, mostrar mensaje específico
    if (missingFields.length > 0) {
      const camposFaltantes = missingFields.join(", ");
      setErrorMessage(
        `Por favor complete los siguientes campos obligatorios: ${camposFaltantes}.`
      );
      return;
    }

    // Si no hay campos faltantes, continuar con la lógica normal
    setErrorMessage("");
    const newUuid = uuid + 1;
    const newForm = [...form, { ...DEFAULT_STATE, uuid: newUuid }];
    setForm(newForm);
    setChildrens(
      newForm.map((item) => (
        <FormAddMember
          key={item.uuid}
          uuid={item.uuid}
          setForm={setForm}
          form={newForm}
          onDelete={handleDelete}
          disableDelete={newForm.length === 1}
          setIsSearchingReniec={setIsSearchingReniec}
        />
      ))
    );
    setUuid(newUuid);
  };
  const isEmailValid = (email) => {
    const emailRegex = /^([\w.%+-ñÑ]+)@([\w-ñÑ]+\.)+([\wñÑ]{2,})$/i;
    return emailRegex.test(email);
  };

  const isDocumentOrEmailDuplicated = (form) => {
    const documentSet = new Set();
    const emailSet = new Set();

    for (const item of form) {
      if (documentSet.has(item.document)) {
        return true; // Documento duplicado
      }
      documentSet.add(item.document);

      if (emailSet.has(item.email)) {
        return true; // Correo duplicado
      }
      emailSet.add(item.email);
    }
    return false; // No hay duplicados
  };

  useEffect(() => {
    setChildrens(
      form.map((item) => (
        <FormAddMember
          key={item.uuid}
          uuid={item.uuid}
          setForm={setForm}
          form={form}
          onDelete={handleDelete}
          disableDelete={form.length === 1}
          setIsSearchingReniec={setIsSearchingReniec}
        />
      ))
    );

    // Verificar si todos los formularios tienen nombre, apellido y alias llenos
    const isAllFormsFilled = form.every(
      (item) =>
        (item.document.length === 8 || item.document.length === 0) &&
        item.name &&
        item.lastName &&
        item.alias &&
        item.rol &&
        item.email &&
        isEmailValid(item.email) &&
        item.telephoneNumber.length === 9
    );

    // Verificar si hay documentos o correos duplicados
    const hasDuplicates = isDocumentOrEmailDuplicated(form);

    // Deshabilitar el botón si no todos los formularios están llenos o hay duplicados
    setIsButtonDisabled(!isAllFormsFilled || hasDuplicates);

    const lastForm = form[form.length - 1];
    const missingFields = [];

    // Validar cada campo y agregar al array si está incompleto
    if (!lastForm.name.trim()) {
      missingFields.push("nombre");
    }
    if (!lastForm.lastName.trim()) {
      missingFields.push("apellido");
    }
    if (!lastForm.alias.trim()) {
      missingFields.push("alias");
    }
    if (lastForm.telephoneNumber.length !== 9) {
      missingFields.push("teléfono");
    }
    if (!lastForm.email || !isEmailValid(lastForm.email)) {
      missingFields.push("correo");
    }
    if (!lastForm.rol) {
      missingFields.push("rol");
    }

    // Actualizar el mensaje de error
    if (missingFields.length === 0) {
      setErrorMessage("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation {...headerProps} />
      <div className={style.container}>
        <h2 className={style.viewTitleContent}>Datos del nuevo empleado</h2>
        <h3>
          Se deben ingresar los datos del empleado en el siguiente formulario.
        </h3>
        <FormAddMore
          buttonText="Agregar otro empleado"
          childrens={childrens}
          buttonAction={handleAddMore}
        />
        {errorMessage && (
          <p style={{ color: "red", marginBottom: "10px" }}>{errorMessage}</p>
        )}
      </div>
    </div>
  );
};
