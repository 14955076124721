import { resendEmployeeInviteAdapter } from "@Adapters/employeesInvites.adapter";

export const handleSubmitInvite = async (
  employee,
  resendStatus,
  setResendStatus,
  dispatch
) => {
  /* The condition checks if the value of "resend state" for this employee is false */
  if (!resendStatus[employee.idEmployee]) {
    setResendStatus({
      ...resendStatus,
      [employee.idEmployee]: true,
    });

    await resendEmployeeInviteAdapter(employee.idEmployee, dispatch);

    // After 10 seconds, the resend state for this employee will be set to false
    setTimeout(() => {
      setResendStatus({
        ...resendStatus,
        [employee.idEmployee]: false,
      });
    }, 10000);
  }
};
