// Function to reset the form values
export const handleResetForm = (setEditedFormValues: Function) => {
  setEditedFormValues({
    email: "",
    position: "",
    lounge: "",
  });
};

// Function to handle the form values
export const handleFieldChange = (e, editedFormValues, setEditedFormValues) => {
  const { name, value } = e.target;
  setEditedFormValues({
    ...editedFormValues,
    [name]: value,
  });
};
