import {
  IEmployeeSales,
  IEmployeeSaleServed,
} from "@Models/interfaces/employees";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const DEFAULT_STATE = {
  salesReport: {
    productSales: undefined,
    servicesSales: undefined,
    totalClients: undefined,
    totalProducts: undefined,
  },
  salesServed: [],
};

const initialState = DEFAULT_STATE;

export const currentEmployeeSalesSlice = createSlice({
  name: "currentEmployeeSales",
  initialState,
  reducers: {
    updateEmployeeSalesReport: (
      state,
      action: PayloadAction<IEmployeeSales>
    ) => {
      return {
        ...state,
        salesReport: action.payload,
      };
    },
    updateEmployeeSalesServed: (
      state,
      action: PayloadAction<IEmployeeSaleServed[]>
    ) => {
      return {
        ...state,
        salesServed: action.payload,
      };
    },
    resetCurrentEmployeeSales: () => {
      return {
        ...DEFAULT_STATE,
      };
    },
  },
});

export default currentEmployeeSalesSlice.reducer;
export const {
  resetCurrentEmployeeSales,
  updateEmployeeSalesReport,
  updateEmployeeSalesServed,
} = currentEmployeeSalesSlice.actions;
