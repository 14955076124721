import { baseUrl, config } from "./constGlobals";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const deleteEmployeeInviteService = async (idInvite: number) => {
  const url = `${baseUrl}/Team/DeleteEmployee?id=${idInvite}`;
  const elimina_invitacion = 47;
  trackUserAction(elimina_invitacion);
  try {
    const response: IResponse = (await axios.delete(url, config)).data;
    return {
      isSuccess: true,
      status: response.status,
      message: response.message || "Invitación eliminada correctamente.",
      data: response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Ups! ocurrió un problema, vuelva a intentarlo mas tarde.",
      data: null,
    };
  }
};

export const deleteEmployeeSchedule = async (idSchedule: number) => {
  const url = `${baseUrl}/Schedules/DeleteSchedule`;
  const payload = {
    data: {
      id: idSchedule,
    },
  };
  try {
    const response = (await axios.delete(url, { ...payload, ...config })).data;

    return {
      isSuccess: true,
      status: response.status,
      message: response.message,
      data: response.data,
    };
  } catch (err) {
    return {
      isSuccess: false,
      status: err?.response?.status || 500,
      message:
        err?.response?.data?.message ||
        "Ups! ocurrió un problema, vuelva a intentarlo mas tarde.",
      data: null,
    };
  }
};

export const deleteEmployeeService = async (idEmployee: number) => {
  const url = `${baseUrl}/Team/DeleteEmployee?id=${idEmployee}`;
  const elimina_empleado = 43;
  trackUserAction(elimina_empleado);
  try {
    const response: IResponse = (await axios.delete(url, config)).data;

    return {
      isSuccessful: true,
      status: response?.status || 200,
      message: response?.message || "Empleado eliminado correctamente.",
      data: response.data,
    };
  } catch (err) {
    return {
      isSuccessful: false,
      status: 400,
      message:
        err.response?.data?.message ||
        "Ups! ocurrió un problema, vuelva a intentarlo mas tarde.",
      data: null,
    };
  }
};
