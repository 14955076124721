import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Styles
//@ts-check
import style from "./EmployeeSales.module.css";

// Components
//@ts-ignore
import { ButtonWithoutIcon, DateInput } from "@viuti/recursos";
import ReportStatCard from "@Components/ui/molecules/ReportStatCard/ReportStatCard";
import SalesReportTable from "@Components/ui/molecules/SalesReportTable/SalesReportTable";

// Models
import {
  BoxIcon,
  CodeBarIcon,
  LoadingPulseIcon,
  PeopleIcon,
  ServiceIcon,
} from "@Models/icons";

// Hooks
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import {
  HeaderContext,
  HeaderContextProps,
} from "@Hooks/useSitesHeaderContext";

// Adapters
import {
  getSalesReportAdapter,
  getServicesReportAdapter,
} from "@Adapters/getSales/getSales.adapter";

// Redux
import {
  resetCurrentEmployeeSales,
  updateEmployeeSalesReport,
  updateEmployeeSalesServed,
} from "@ReduxService/states/currentEmployeeSalesSlice";

// Utilities
import { formatToSoles } from "@Utilities/texts";
import {
  handleChangeEndPeriod,
  handleChangeStartPeriod,
} from "./functions/handleChangePeriod";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";

const EmployeeSales = () => {
  const { idEmployee } = useParams();
  const dispatch = useAppDispatch();
  const headerContext = useContext(HeaderContext) as HeaderContextProps;

  headerContext.handleIsHidden(true);

  //? State for the selected employee sales
  const selectedEmployeeSalesReport = useAppSelector(
    (state) => state.currentEmployeeSales.salesReport
  );
  const selectedEmployeeSalesServed = useAppSelector(
    (state) => state.currentEmployeeSales.salesServed
  );

  const [tableFetchStatus, setTableFetchStatus] = useState(null);

  const [salesPeriod, setSalesPeriod] = useState({
    startPeriod: new Date().toISOString().split("T")[0],
    endPeriod: new Date().toISOString().split("T")[0],
  });

  const getReporteVentasData = async () => {
    if (salesPeriod.startPeriod && salesPeriod.endPeriod) {
      setTableFetchStatus(0);
      const salesReport = await getSalesReportAdapter(
        Number(idEmployee),
        salesPeriod.startPeriod,
        salesPeriod.endPeriod
      );
      const servicesReport = await getServicesReportAdapter(
        dispatch,
        Number(idEmployee),
        salesPeriod.startPeriod,
        salesPeriod.endPeriod
      );

      if (!salesReport.isSuccess || !servicesReport.isSuccess) {
        dispatch(
          updateNotificationMessage("Error al obtener el reporte de ventas")
        );
        dispatch(updateNotificationStatus(500));
        setTableFetchStatus(salesReport.status);
        return;
      }

      dispatch(resetCurrentEmployeeSales());
      dispatch(updateEmployeeSalesReport(salesReport.data));
      dispatch(updateEmployeeSalesServed(servicesReport.data));
      setTableFetchStatus(servicesReport.status);
    }
  };

  const isSubmitBttnDisabled =
    salesPeriod.startPeriod === "" ||
    salesPeriod.endPeriod === "" ||
    salesPeriod.startPeriod > salesPeriod.endPeriod;

  useEffect(() => {
    getReporteVentasData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.viewContainer}>
      {tableFetchStatus === 0 && (
        <div className={style.loadingContainer}>
          <img src={LoadingPulseIcon} alt="Cargando..." />
        </div>
      )}
      <div className={style.periodSearchBarContainer}>
        <div className={style.periodSearchBarContent}>
          <DateInput
            label="Desde"
            inputProps={{
              value: salesPeriod.startPeriod,
              onChange: (e) => handleChangeStartPeriod(e, setSalesPeriod),
            }}
          />
          <DateInput
            label="Hasta"
            inputProps={{
              value: salesPeriod.endPeriod,
              onChange: (e) => handleChangeEndPeriod(e, setSalesPeriod),
            }}
          />
          <ButtonWithoutIcon
            isPrimary
            textBttn={"Cargar"}
            handleClick={getReporteVentasData}
            isDisabled={isSubmitBttnDisabled}
          />
        </div>
      </div>
      <div className={style.viewDataContainer}>
        <div className={style.reportCardsContainer}>
          <ReportStatCard
            icon={PeopleIcon}
            value={selectedEmployeeSalesReport.totalClients}
            title={"Clientes atendidos"}
          />
          <ReportStatCard
            icon={BoxIcon}
            value={selectedEmployeeSalesReport.totalProducts}
            title={"Productos vendidos"}
          />
          <ReportStatCard
            icon={ServiceIcon}
            value={formatToSoles(selectedEmployeeSalesReport.servicesSales)}
            title={"Total de servicios"}
          />
          <ReportStatCard
            icon={CodeBarIcon}
            value={formatToSoles(selectedEmployeeSalesReport.productSales)}
            title={"Total de productos"}
          />
        </div>
        <div className={style.salesTableContainer}>
          <h1 className={style.salesTableTitle}>Servicios realizados</h1>
          <SalesReportTable
            tableContent={selectedEmployeeSalesServed}
            status={tableFetchStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeeSales;
