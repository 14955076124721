import { putGiveAccessToEmployee_service } from "@Services/Employee/putGiveAccessToEmployee.service";

export const giveAccess_adapter = async (idEmployee) => {
  const response = await putGiveAccessToEmployee_service(idEmployee);
  if (!response.isSuccess) {
    return {
      isSuccess: false,
      status: response.status,
      data: response.data,
      message: response.message,
    };
  }

  return {
    isSuccess: true,
    status: response.status,
    data: response.data,
    message: response.message,
  };
};
