import { STATUS_OK } from "@Models/httpsStatus";
import { editEmployeePicture } from "@Services/puts";

export const updateProfilePicAdapter = async (
  idEmployee: number,
  file: { file: string; fileName: string }
) => {
  // Regex to verify if it is a URL
  const patronUrl = /^https?:\/\/\S+/;
  // Regex to verify if it is a base64 link
  const patronBase64 = /^data:image\/\w+;base64,/;

  if (patronUrl.test(file.file)) {
    return {
      isSuccess: true,
      status: STATUS_OK,
      data: "URL",
      message: "URL",
    };
  } else if (patronBase64.test(file.file)) {
    // Transform the file into a blob
    const blob = await fetch(file.file).then((r) => r.blob());
    const formData = new FormData();
    formData.append("File", blob, file.fileName);
    formData.append("idEmployee", idEmployee.toString());
    const response: IResponse = await editEmployeePicture(formData);

    if (response.status !== STATUS_OK) {
      return {
        isSuccess: false,
        status: response.status,
        data: response.data,
        message: response.message,
      };
    }

    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      message: response.message,
    };
  } else {
    return {
      isSuccess: true,
      status: STATUS_OK,
      data: "URL",
      message: "URL",
    };
  }
};
