import { AppDispatch } from "@ReduxService/index";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { getSalesCommissions_service } from "@Services/gets";

export const getSalesCommissions = async (
  dispatch: AppDispatch,
  employeeId: number,
  startPeriod: string,
  endPeriod: string
) => {
  const response: IResponse = await getSalesCommissions_service(
    employeeId,
    startPeriod,
    endPeriod
  );

  if (!response.isSuccess) {
    dispatch(updateNotificationMessage(response.message));
    dispatch(updateNotificationStatus(response.status));
    return response;
  }

  return response;
};
