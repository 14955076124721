import { IEmployee } from "@Models/interfaces/employees";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const DEFAULT_STATE = [];

const initialState: IEmployee[] = (() => {
  const persistedState = localStorage.getItem("__redux__state__");
  return persistedState ? JSON.parse(persistedState).users : DEFAULT_STATE;
})();

export const employeesSlice = createSlice({
  name: "listEmployees",
  initialState,
  reducers: {
    updateEmployees: (state, action: PayloadAction<IEmployee[]>) => {
      return action.payload;
    },
    addEmployee: (state, action: PayloadAction<IEmployee>) => {
      state.unshift(action.payload);
    },
    modifyEmployee: (state, action: PayloadAction<IEmployee>) => {
      const employeeIndex = state.findIndex(
        (employee) => employee.idEmployee === action.payload.idEmployee
      );
      state[employeeIndex] = action.payload;
    },
    deleteEmployee: (state, action: PayloadAction<number>) => {
      const employeeIndex = state.findIndex(
        (employee) => employee.idEmployee === action.payload
      );
      state.splice(employeeIndex, 1);
    },
  },
});

export default employeesSlice.reducer;
export const { updateEmployees, addEmployee, modifyEmployee, deleteEmployee } =
  employeesSlice.actions;
