import { getCommmisionEmployeeDetail_service } from "@Services/Employee/getCommmisionEmployeeDetail.service";

export const getCommmisionEmployeeDetail_adapter = async (
  commisionId: number
) => {
  const response = await getCommmisionEmployeeDetail_service(commisionId);
  if (!response.isSuccess) {
    return {
      ...response,
      data: {},
    };
  }

  // Separamos los items en productos y servicios
  const servicios = response.data.commisionItems
    .filter((item) => item.getCommisionDetailServices !== null)
    .map((item) => {
      const service = item.getCommisionDetailServices;
      return {
        ...service,
        serviceName: `${service.serviceName}: ${service.priceServiceName}`,
      };
    });

  const productos = response.data.commisionItems
    .filter((item) => item.getCommsionDetailProduct !== null)
    .map((item) => item.getCommsionDetailProduct);

  const adaptData = {
    commisionName: response.data.commisionName,
    commisionId: response.data.commisionId,
    methodId: response.data.methodId,
    methodDescription: response.data.methodDescription,
    typeDiscountId: response.data.typeDiscountId,
    typeDiscountDescription: response.data.typeDiscountDescription,
    goal: response.data.goal,
    amount: response.data.amount,
    condicionPromotion: response.data.condicionPromotion,
    condicionTax: response.data.condicionTax,
    dateCreation: response.data.dateCreation,
    employeeCreationName: response.data.employeeCreationName,
    employeeCreationId: response.data.employeeCreationId,
    productos,
    servicios,
    commisionEmployee: response.data.commisionEmployee,
  };

  return {
    ...response,
    data: adaptData,
  };
};
