import axios from "axios";
import { baseUrl, config } from "./constGlobals";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const deleteEmployeeServicesService = async (body: {
  id: number;
  idEmployee: number;
}) => {
  const url = `${baseUrl}/Services/DeleteServices`;
  try {
    const response = await axios.put(url, body, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const editEmployeeService = async (body: object) => {
  const url = `${baseUrl}/EditEmployeeData/EditEmployeeData`;
  const edita_empleado = 42;
  trackUserAction(edita_empleado);
  try {
    const response = (await axios.put(url, body, config)).data;
    return {
      isSuccess: true,
      status: response.status,
      message: response.message,
      data: response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Ups! ocurrió un problema, vuelva a intentarlo mas tarde.",
      data: null,
    };
  }
};

export const editEmployeePicture = async (payload: object) => {
  const tokenLocal = localStorage.getItem("access_token");
  const url = `${baseUrl}/ProfilePicture/EditProfilePhoto`;

  try {
    const response = await axios.put(url, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tokenLocal}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const editEmployeeSchedulesService = async (body: object) => {
  const url = `${baseUrl}/Schedules/MassiveEditionOfSchedules`;
  try {
    const response = await axios.put(url, body, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
