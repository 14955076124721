import { employeeBaseUrl, ApiResponse, getData } from "@Services/constServices";

export const getListOfEmployeeCommissions_service = async (
  employeeId: number
): Promise<ApiResponse> => {
  const url = `${employeeBaseUrl}CommissionEmployee/GetCommisionForEmployee?employeeId=${employeeId}`;
  const successMessage =
    "Se ha obtenido el listado de comisiones de los empleados exitosamente";
  const errorMessage =
    "Error al obtener el listado de comisiones de los empleados, intente nuevamente";
  return await getData(url, successMessage, errorMessage);
};
