import { putData, teamBaseUrl } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const putGiveAccessToEmployee_service = async (idEmployee) => {
  const url = `${teamBaseUrl}/EmployeeWithOutAccessToEmployeeWithAccess?idEmployee=${idEmployee}`;
  const otorga_acceso_empleado = 44;
  trackUserAction(otorga_acceso_empleado);
  const successMessage = "Se ha otorgado el acceso al empleado exitosamente";
  const errorMessage = "Error al otorgar el acceso al empleado";
  return await putData(url, {}, successMessage, errorMessage);
};
