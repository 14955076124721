import { employeeBaseUrl, ApiResponse, getData } from "@Services/constServices";

export const getCommmisionEmployeeDetail_service = async (
  commisionId: number
): Promise<ApiResponse> => {
  const url = `${employeeBaseUrl}CommissionEmployee/GetCommmisionEmployeeDetail?commisionId=${commisionId}`;
  const successMessage =
    "Se ha obtenido el detalle de la comisión exitosamente";
  const errorMessage =
    "Error al obtener el detalle de la comisión, intente nuevamente";
  return await getData(url, successMessage, errorMessage);
};
