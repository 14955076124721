import { baseUrl, config } from "./constGlobals";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

import axios from "axios";

export const postEmployeeService = async (data) => {
  const url = `${baseUrl}/Team/Invitation`;
  const invita_miembro = 46;
  trackUserAction(invita_miembro);
  try {
    const response: IResponse = await axios.post(url, data, config);
    return {
      isSuccess: true,
      status: response.status,
      message: response.message,
      data: response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error.response.status,
      message:
        error.response?.data?.message ||
        "Ups! ocurrió un problema, vuelva a intentarlo mas tarde.",
      data: {
        idEmployee: error?.response?.data?.data?.idEmploye,
      },
    };
  }
};

export const resendInviteService = async (idEmployee: number) => {
  const url = `${baseUrl}/Team/ReInvitation?id=${idEmployee}`;
  const reenvia_invitacion = 48;
  trackUserAction(reenvia_invitacion);
  try {
    const response: IResponse = await axios.post(url, null, config);

    return {
      isSuccess: true,
      status: response.data.status,
      message: response.data.message,
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Ups! ocurrió un problema, vuelva a intentarlo mas tarde.",
      data: null,
    };
  }
};

export const addEmployeeServicesService = async (body: {
  idService: number;
  idEmployee: number;
}) => {
  const url = `${baseUrl}/Services/RegisterServicesById`;
  try {
    const response: IResponse = await axios.post(url, body, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
