import {
  IEmployeeSaleServed,
  IEmployeeSales,
} from "@Models/interfaces/employees";
import { AppDispatch } from "@ReduxService/index";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import {
  getSalesReportService,
  getServicesTableReportService,
} from "@Services/gets";

export const getSalesReportAdapter = async (
  idEmployee: number,
  startPeriod: string,
  endPeriod: string
) => {
  const response: IResponse = await getSalesReportService(
    idEmployee,
    startPeriod,
    endPeriod
  );

  if (!response.isSuccess) {
    return response;
  }

  const formattedData: IEmployeeSales = {
    productSales: response.data.totalProducts,
    servicesSales: response.data.totalServices,
    totalClients: response.data.totalCustomers,
    totalProducts: response.data.numberOfProducts,
  };

  return {
    ...response,
    data: formattedData,
  };
};

export const getServicesReportAdapter = async (
  dispatch: AppDispatch,
  idEmployee: number,
  startPeriod: string,
  endPeriod: string
) => {
  const response: IResponse = await getServicesTableReportService(
    idEmployee,
    startPeriod,
    endPeriod
  );

  if (!response.isSuccess) {
    dispatch(updateNotificationMessage(response.message));
    dispatch(updateNotificationStatus(response.status));
    return response;
  }

  const fotmattedData: IEmployeeSaleServed[] = response.data.map(
    (sale: any) => {
      return {
        discount: sale.discount,
        date: sale.date,
        productPrice: sale.amount,
        commandNumber: sale.commandNumber,
        productId: sale.product,
        service: sale.service,
        totalSale: sale.totalCollected,
      };
    }
  );

  return {
    ...response,
    data: fotmattedData,
  };
};
