import { getPositionsAdapter } from "@Adapters/getPositions/getPositions.adapter";
import { useAppDispatch } from "@Hooks/store";
import React, { useEffect } from "react";

export const useRoles = () => {
  const [roles, setRoles] = React.useState([{ value: 1, name: "Admin" }]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const response = await getPositionsAdapter(dispatch);
      if (response.isSuccess) {
        setRoles(response.data);
      } else {
        setRoles(roles);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return { roles };
};
