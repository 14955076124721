import { commissions_mock } from "../_mocks_/commissions";
import { accountBaseUrl, baseUrl, config } from "./constGlobals";
import axios from "axios";
import { loungesBaseUrl } from "./constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getEmployeesService = async () => {
  const url = `${baseUrl}/Team/TeamMembers`;
  const ingresa_vista_empleados = 40;
  trackUserAction(ingresa_vista_empleados);
  const response: IResponse = await axios
    .get(url, config)
    .then((res) => res.data);
  return response;
};

export const getApiSites = async () => {
  try {
    const response = await axios.get(
      `${loungesBaseUrl}/lounges/lounges`,
      config
    );
    return {
      isSuccess: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: 500,
      message: error?.response?.data?.message || "Error al obtener las sedes",
      data: null,
    };
  }
};

export const getEmployeeInfoService = async (idEmployee: number) => {
  const url = `${baseUrl}/InfoEmployee/InfoEmployee?idEmployee=${idEmployee}`;
  const ingresa_vista_empleado = 41;
  trackUserAction(ingresa_vista_empleado);
  try {
    const response = await axios.get(url, config).then((res) => {
      return res.data;
    });
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: 500,
      message:
        error?.response?.data?.message ||
        "Error al obtener la información del empleado",
      data: null,
    };
  }
};

export const getPositionsService = async () => {
  const url = `${accountBaseUrl}/Roles/GetRoles`;
  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message: error?.response?.data?.message || "Error al obtener los roles",
      data: [],
    };
  }
};

export const getEmployeeServicesService = async (idEmployee: number) => {
  const url = `${baseUrl}/Services/ListServicesEmployee?idEmployee=${idEmployee}`;
  try {
    const response = await axios.get(url, config).then((res) => {
      return res.data;
    });
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message || "Error al obtener los servicios",
      data: [],
    };
  }
};

export const getAllServicesService = async () => {
  const url = `${baseUrl}/Services/ListServices`;
  try {
    const response: IResponse = await axios.get(url, config).then((res) => {
      return res.data;
    });
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message || "Error al obtener los servicios",
      data: [],
    };
  }
};

export const getEmployeeStatsService = async (idEmployee: number) => {
  const url = `${baseUrl}/StatisticsEmployee/StatisticsEmployee?idEmployee=${idEmployee}`;

  try {
    const response: IResponse = await axios
      .get(url, config)
      .then((res) => res.data);
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: 500,
      message:
        error?.response?.data?.message || "Error al obtener las estadísticas",
      data: null,
    };
  }
};

export const getEmployeeLoyaltyDataService = async (idEmployee: number) => {
  const url = `${baseUrl}/StatisticsEmployee/Loyalty?idEmployee=${idEmployee}`;
  try {
    const response = await axios.get(url, config).then((res) => res);
    return {
      isSuccess: true,
      ...response.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: 500,
      message:
        error?.response?.data?.message ||
        "Error al obtener la información del empleado",
      data: null,
    };
  }
};

export const getSalesReportService = async (
  idEmployee: number,
  startPeriod: string,
  endPeriod: string
) => {
  const url = `${baseUrl}/SalesReport/SalesReport?idEmployee=${idEmployee}&dateFrom=${startPeriod}&dateTo=${endPeriod}`;
  try {
    const response = await axios.get(url, config).then((res) => res.data);
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Error al obtener el reporte de ventas",
      data: null,
    };
  }
};

export const getSalesCommissions_service = async (
  idEmployee: number,
  startPeriod: string,
  endPeriod: string
) => {
  const url = `${baseUrl}/SalesReport/SalesReport?idEmployee=${idEmployee}&dateFrom=${startPeriod}&dateTo=${endPeriod}`;
  try {
    // const response = await axios.get(url, config).then((res) => res.data);
    const response = {
      data: commissions_mock,
      message: "Comisiones obtenidas correctamente",
      status: 200,
    };
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message ||
        "Error al obtener las comisiones de ventas",
      data: null,
    };
  }
};

export const getServicesTableReportService = async (
  idEmployee: number,
  startPeriod: string,
  endPeriod: string
) => {
  const url = `${baseUrl}/SalesReport/DetailedSalesReport?idEmployee=${idEmployee}&dateFrom=${startPeriod}&dateTo=${endPeriod}`;
  try {
    const response = await axios.get(url, config).then((res) => res.data);
    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: 500,
      message:
        error?.response?.data?.message ||
        "Error al obtener el reporte de ventas",
      data: null,
    };
  }
};

export const getScheduleEmployeeService = async (idEmployee: number) => {
  const url = `${baseUrl}/Schedules/ListSchedules?idEmployee=${idEmployee}`;
  try {
    const response: IResponse = await axios.get(url, config).then((res) => {
      return res.data;
    });

    return {
      isSuccess: true,
      ...response,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      message:
        error?.response?.data?.message || "Error al obtener los horarios",
      data: null,
    };
  }
};
