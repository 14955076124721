// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CJROYNr5Hv6XoG0d9k8o {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  gap: 10px;
  padding: 30px;
  flex-wrap: wrap;
}

.GammzFpJddPm87UkCr4K {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.wMinXnlv20YWtrAlzKcS {
  width: 26px;
  height: 26px;
}

.QbmXI67oFaMYh0E_NHlr {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #45348e;
  margin-right: 30px;
}

.SnljQXJyekoSp2d5cj56 {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  color: #392b75;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/ReportStatCard/ReportStatCard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,SAAS;EACT,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".cardContainer {\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  border: 1px solid #d3d3d3;\n  border-radius: 8px;\n  gap: 10px;\n  padding: 30px;\n  flex-wrap: wrap;\n}\n\n.card__symbol_and_value {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n}\n\n.cardIcon {\n  width: 26px;\n  height: 26px;\n}\n\n.cardValue {\n  font-family: \"Mulish\", sans-serif;\n  font-weight: 700;\n  font-size: 24px;\n  color: #45348e;\n  margin-right: 30px;\n}\n\n.cardTitle {\n  font-family: \"Mulish\", sans-serif;\n  font-weight: 400;\n  font-size: 12px;\n  white-space: nowrap;\n  color: #392b75;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `CJROYNr5Hv6XoG0d9k8o`,
	"card__symbol_and_value": `GammzFpJddPm87UkCr4K`,
	"cardIcon": `wMinXnlv20YWtrAlzKcS`,
	"cardValue": `QbmXI67oFaMYh0E_NHlr`,
	"cardTitle": `SnljQXJyekoSp2d5cj56`
};
export default ___CSS_LOADER_EXPORT___;
