import React from "react";
import style from "./ErrorView.module.css";

const ErrorView = ({ children }) => {
  return (
    <div className={style.errorViewContainer}>
      <h1 className={style.primaryErrorMsg}>{children}</h1>
      <h2 className={style.secondaryErrorMsg}>Por favor, intente más tarde.</h2>
    </div>
  );
};

export default ErrorView;
