// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bQnFSD76jje6xLf6V07r {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  gap: 8px;
  background-color: #fff;
}

.nGelOBO3rAsVJJY5XyGd {
  padding: 12px 24px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  position: relative;
  transition: all 0.2s ease;
}

.nGelOBO3rAsVJJY5XyGd:hover {
  color: #152756;
}

.nGelOBO3rAsVJJY5XyGd.TFtc1ykIFicw2gZ6Xhaw {
  color: #152756;
}

.nGelOBO3rAsVJJY5XyGd.TFtc1ykIFicw2gZ6Xhaw::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #152756;
}

@media (max-width: 768px) {
  .nGelOBO3rAsVJJY5XyGd {
    padding: 12px 16px;
    font-size: 13px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/Tabs/Tabs.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,QAAQ;EACR,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,WAAW;EACX,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE;IACE,kBAAkB;IAClB,eAAe;EACjB;AACF","sourcesContent":[".tabsContainer {\n  display: flex;\n  border-bottom: 1px solid #e0e0e0;\n  gap: 8px;\n  background-color: #fff;\n}\n\n.tab {\n  padding: 12px 24px;\n  border: none;\n  background: none;\n  cursor: pointer;\n  font-size: 14px;\n  font-weight: 500;\n  color: #666;\n  position: relative;\n  transition: all 0.2s ease;\n}\n\n.tab:hover {\n  color: #152756;\n}\n\n.tab.active {\n  color: #152756;\n}\n\n.tab.active::after {\n  content: \"\";\n  position: absolute;\n  bottom: -1px;\n  left: 0;\n  width: 100%;\n  height: 2px;\n  background-color: #152756;\n}\n\n@media (max-width: 768px) {\n  .tab {\n    padding: 12px 16px;\n    font-size: 13px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsContainer": `bQnFSD76jje6xLf6V07r`,
	"tab": `nGelOBO3rAsVJJY5XyGd`,
	"active": `TFtc1ykIFicw2gZ6Xhaw`
};
export default ___CSS_LOADER_EXPORT___;
