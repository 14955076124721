import { IDeleteEmployeeAdapter } from "@Models/interfaces/employees";
import { deleteEmployeeService } from "@Services/deletes";

export const deleteEmployeeAdapter = async (idEmployee: number) => {
  const response: IDeleteEmployeeAdapter = await deleteEmployeeService(
    idEmployee
  );

  return {
    isSuccessful: response.isSuccessful,
    status: response.status,
    message: response.message,
    data: response.data,
  };
};
