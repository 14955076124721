import React, { createContext, useState } from "react";

export interface HeaderContextProps {
  textBttn: string;
  handleTextBtnChange: (text: string) => void;
  clickFunction: () => void;
  handleClickChange: (clickFunction: () => void) => void;
  isDisabled: boolean;
  handleIsDisabled: (isDisabled: boolean) => void;
  isHidden: boolean;
  handleIsHidden: (isHidden: boolean) => void;
  isLoading: boolean;
  handleIsLoading: (isLoading: boolean) => void;
}

const HeaderContext = createContext<HeaderContextProps | {}>({});

const HeaderProvider = ({ children }) => {
  const [textBttn, setTextBttn] = useState("Guardar");
  const [clickFunction, setClickFunction] = useState(() => {});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isHidden, setIsHidden] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleTextBtnChange = (text: string) => {
    setTextBttn(text);
  };

  const handleClickChange = (clickFunction: Function) => {
    setClickFunction(() => clickFunction);
  };

  const handleIsDisabled = (isDisabled: boolean) => {
    setIsDisabled(isDisabled);
  };

  const handleIsHidden = (isHidden: boolean) => {
    setIsHidden(isHidden);
  };

  const handleIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
  };

  const contextValue = {
    textBttn,
    handleTextBtnChange,
    clickFunction,
    handleClickChange,
    isDisabled,
    handleIsDisabled,
    isHidden,
    handleIsHidden,
    isLoading,
    handleIsLoading,
  };

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderContext, HeaderProvider };
