import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
export interface ICommissionsToPayForEmployee {
  commisionId: number;
  commisionName: string;
  typeAmount: number;
  typeAmountDescription: string;
  amount: number;
  goal: number;
  accumulated: number;
  startDate: string;
  endDate: string;
  progress: number;
  totalPayCommision: number;
  methodId: number;
  methodDescription: string;
  itemsToPayCommisionResponse: IItemsToPayCommisionResponse[];
}
export interface IItemsToPayCommisionResponse {
  documentId: number;
  documentNumber: string;
  documentDetailId: number;
  description: string;
  priceServiceId: number | null;
  localProductId: number;
  productId: number;
  packageId: number | null;
  packageDocumentDetailId: number | null;
  grossAmount: number;
  taxDiscount: number;
  generalDiscountProporcional: number;
  totalPayItemCommision: number;
  baseAmountToPayCommision: number;
  itemQuantity: number;
  creationDate: string;
}

const DEFAULT_STATE = {
  commissionsToPayForEmployee: [],
};

const initialState = DEFAULT_STATE;

export const commissionsToPayForEmployeeSlice = createSlice({
  name: "commissionsToPayForEmployee",
  initialState,
  reducers: {
    updateCommissionsToPayForEmployee: (
      state,
      action: PayloadAction<ICommissionsToPayForEmployee[]>
    ) => {
      return {
        ...state,
        commissionsToPayForEmployee: action.payload,
      };
    },
  },
});

export default commissionsToPayForEmployeeSlice.reducer;
export const { updateCommissionsToPayForEmployee } =
  commissionsToPayForEmployeeSlice.actions;

//   {
//     "status": 200,
//     "message": "La operación se realizó con éxito",
//     "data": {
//         "commisionId": 58,
//         "commisionDescription": "Comision por navidad",
//         "goal": 100.00,
//         "accumulated": 506.77966101694915254237288136,
//         "startDate": "2024-11-01",
//         "endDate": "2024-11-28",
//         "progress": 506.77966101694915254237288136,
//         "totalPayCommision": 160.00,
//         "itemsToPayCommisionResponse": [
//             {
//                 "documentId": 484434,
//                 "documentNumber": "N001 - 00000052",
//                 "documentDetailId": 204,
//                 "description": "Headset Gamer HyperX Cloud II",
//                 "priceServiceId": null,
//                 "localProductId": 256460,
//                 "productId": 111890,
//                 "packageId": null,
//                 "packageDocumentDetailId": null,
//                 "grossAmount": 598.000,
//                 "taxDiscount": 91.22033898305084745762711864,
//                 "generalDiscountProporcional": 0.000,
//                 "totalPayItemCommision": 160.00,
//                 "baseAmountToPayCommision": 506.77966101694915254237288136,
//                 "itemQuantity": 2,
//                 "creationDate": "27/11/2024 23:18:13"
//             }
//         ]
//     }
// }
