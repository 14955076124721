import { ICommission } from "@Models/interfaces/commissions";

export const commissions_mock: ICommission[] = [
  {
    date: "05/05/2024",
    saleNumber: "Nº-0000000003",
    rules: {
      id: 1,
      name: "Christmas Commission",
      formula: {
        salesThreshold: "2000",
        period: "semanal",
        method: "Comision por venta",
        modality: "efectivo",
        amount: "30",
        taxIncluded: false,
      },
    },
    amount: 65.2,
    isRuleApplied: true,
    status: {
      status: 1,
      label: "Pendiente",
    },
  },
  {
    date: "18/04/2024",
    saleNumber: "Nº-0000000002",
    rules: {
      id: 1,
      name: "Christmas Commission",
      formula: {
        salesThreshold: "2000",
        period: "semanal",
        method: "Comision por venta",
        modality: "porcentaje",
        amount: "50",
        taxIncluded: false,
      },
    },
    amount: 30.0,
    isRuleApplied: false,
    status: {
      status: 1,
      label: "Pendiente",
    },
  },
  {
    date: "21/03/2024",
    saleNumber: "Nº-0000000001",
    rules: {
      id: 1,
      name: "Christmas Commission",
      formula: {
        salesThreshold: "2000",
        period: "semanal",
        method: "Comision por venta",
        modality: "efectivo",
        amount: "30",
        taxIncluded: false,
      },
    },
    amount: 150.0,
    isRuleApplied: true,
    status: {
      status: 2,
      label: "Pagado",
    },
  },
];
