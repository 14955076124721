import React, { Dispatch, useEffect, useRef, useState } from "react";

// Components
// @ts-ignore
import { ButtonWithoutIcon, Icon } from "@viuti/recursos";
import { TimesIcon } from "@Models/icons";
import LoadingScreen from "@Components/ui/atoms/LoadingScreen/LoadingScreen";

// Style
import style from "./confirmModal.module.css";
import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";

interface ConfirmModalProps {
  title: string;
  message: string;
  buttonLbl: string;
  controller: {
    visible: boolean;
    setVisible: Dispatch<boolean>;
  };
  confirmAction: any;
  dispatch?: AppDispatch;
}

const ConfirmModal = (Props: ConfirmModalProps) => {
  const {
    title,
    message,
    buttonLbl,
    controller,
    confirmAction,
    dispatch = null,
  } = Props;
  const { visible, setVisible } = controller;

  const [isLoading, setIsLoading] = useState(false);
  const modal = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modal.current && !modal.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modal, setVisible]);

  const handleConfirm = async () => {
    setIsLoading(true);
    if (typeof confirmAction === "function") {
      const response = await confirmAction();
      if (response?.isSuccess) {
        setVisible(false);
        dispatch(updateNotificationMessage("Empleado eliminado correctamente"));
        dispatch(updateNotificationStatus(200));
      }
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`${style.layoutContainer} ${
        visible ? style.visible : style.hidden
      }`}
    >
      <div className={`${style.layoutContainer} `}>
        <div className={style.modalContainer} ref={modal}>
          <button
            className={style.closeButtonContainer}
            onClick={() => setVisible(false)}
          >
            <Icon path={TimesIcon} color={"#B4B4B4"} size={14} />
          </button>
          <p className={style.modalTitle}>{title}</p>
          <p className={style.modalMessage}>{message}</p>
          <ButtonWithoutIcon
            isPrimary
            textBttn={buttonLbl}
            handleClick={handleConfirm}
          />
          <LoadingScreen visible={isLoading} loaderWidth={150} />
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
