import React, { useEffect, useRef, useState } from "react";
import style from "./EditServicesModal.module.css";
// @ts-ignore
import { ButtonWithoutIcon, Icon, TextInput } from "@viuti/recursos";
import {
  getAllServicesAdapter,
  updateEmployeeServicesAdapter,
} from "@Adapters/getServices/getServices.adapter";
import { MagnifyingGlassIcon, TimesIcon } from "@Models/icons";
import { clickOusideModalCloses } from "./functions/clickOusideModalCloses";
import { handleServiceChange } from "./functions/handleServiceChange";
import { isServiceSelected } from "./functions/isServiceSelected";
import { useAppDispatch, useAppSelector } from "@Hooks/store";

const EditServicesModal = ({
  initialServicesState,
  setInitialServicesState,
  visible,
  setVisible,
  idEmployee,
}) => {
  const dispatch = useAppDispatch();
  const employeesState = useAppSelector((state) => state.employeesList);
  const selectedEmployee = employeesState?.find(
    (employee) => employee.idEmployee === Number(idEmployee)
  );
  const [allServices, setAllServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isSubmitBttnDisabled, setIsSubmitBttnDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [searchServicesValue, setSearchServicesValue] = useState("");
  const [filteredServices, setFilteredServices] = useState([]);

  useEffect(() => {
    setSelectedServices(initialServicesState);
  }, [initialServicesState]);

  const modalLayout = useRef(null);
  useEffect(() => {
    clickOusideModalCloses(modalLayout, setVisible);
    // eslint-disable-next-line
  }, [modalLayout]);

  useEffect(() => {
    if (
      JSON.stringify(selectedServices) !== JSON.stringify(initialServicesState)
    ) {
      setIsSubmitBttnDisabled(false);
    } else {
      setIsSubmitBttnDisabled(true);
    }
    // eslint-disable-next-line
  }, [selectedServices]);

  useEffect(() => {
    getAllServicesAdapter().then((res) => {
      setAllServices(res.data);
      setFilteredServices(res.data);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchServicesValue === "") return setFilteredServices(allServices);
    const filteredServices = allServices.filter((service) =>
      service.name.toLowerCase().includes(searchServicesValue.toLowerCase())
    );
    setFilteredServices(filteredServices);
    // eslint-disable-next-line
  }, [searchServicesValue]);

  const handleSubmitServices = async () => {
    setIsSubmitLoading(true);
    await updateEmployeeServicesAdapter(
      idEmployee,
      selectedEmployee,
      initialServicesState,
      setInitialServicesState,
      selectedServices,
      dispatch,
      setVisible
    );
    setIsSubmitLoading(false);
  };

  const searchProps = {
    value: searchServicesValue,
    placeholder: "Buscar servicios...",
    handleChange: (e) => setSearchServicesValue(e.target.value),
    leftIcon: MagnifyingGlassIcon,
    width: "100%",
  };

  const handleCheckboxClick = (service) => {
    handleServiceChange(service, selectedServices, setSelectedServices);
  };

  return (
    <div
      className={`${style.container} ${visible ? style.visible : style.hidden}`}
      ref={modalLayout}
    >
      <div className={style.containerLowerPart}>
        <div className={style.containerButton}>
          <h3>Servicios</h3>
          <button
            className={style.closeModalBtn}
            onClick={() => setVisible(false)}
          >
            <Icon path={TimesIcon} alt="X" size={14} color={"#B4B4B4"} />
          </button>
        </div>
        <div className={style.serviciosContainer}>
          <p>Selecciona los servicios que deseas agregar al empleado</p>
          <TextInput {...searchProps} />
          <button
            className={style.selectAllBtn}
            onClick={() => setSelectedServices(allServices)}
          >
            Seleccionar todos
          </button>
          {filteredServices.length === 0 && searchServicesValue !== "" && (
            <p className={style.noServicesMessage}>
              No se encontraron servicios
            </p>
          )}
          <div className={style.checkboxGroup}>
            {filteredServices.length > 0 &&
              filteredServices.map((service) => {
                return (
                  <button
                    key={service.idService}
                    className={style.checkboxContainer}
                    onClick={() => handleCheckboxClick(service)}
                    style={{ cursor: "pointer" }}
                  >
                    <input
                      type="checkbox"
                      checked={isServiceSelected(service, selectedServices)}
                      onChange={() => handleCheckboxClick(service)}
                    />
                    <p className={style.serviceName}>
                      {service.name.toUpperCase()}
                    </p>
                  </button>
                );
              })}
          </div>
        </div>
        <div className={style.containerGuardar}>
          <ButtonWithoutIcon
            isPrimary
            textBttn={"Guardar"}
            handleClick={handleSubmitServices}
            isDisabled={isSubmitBttnDisabled || isSubmitLoading}
            isLoading={isSubmitLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default EditServicesModal;
