import { getHistoryEmployeeCommissions_service } from "@Services/Employee/getHistoryEmployeeCommissions.service";

export const getHistoryEmployeeCommissions_adapter = async (
  employeeId: number,
  page: number,
  commisionId: number
) => {
  const response = await getHistoryEmployeeCommissions_service(
    employeeId,
    page,
    commisionId
  );
  if (!response.isSuccess) {
    return {
      ...response,
      data: [],
    };
  }
  const adaptData = response.data.commisionPaymentHistory;

  return {
    ...response,
    data: adaptData,
  };
};
