import { deleteEmployeeAdapter } from "@Adapters/deleteEmployee/deleteEmployee";
import { AppDispatch } from "@ReduxService/index";
import { deleteEmployee } from "@ReduxService/states/employeesSlice";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";

export const handleDeleteEmployee = async (
  idEmployee: number,
  dispatch: AppDispatch,
  setIsDeleteModalVisible
) => {
  const backendResponse = await deleteEmployeeAdapter(idEmployee);

  if (backendResponse.isSuccessful) {
    setIsDeleteModalVisible(false);
    dispatch(updateNotificationMessage("Empleado eliminado correctamente"));
    dispatch(updateNotificationStatus(200));
    dispatch(deleteEmployee(idEmployee));
    setTimeout(() => {
      window.location.href = "/empleados";
    }, 2000);
    return backendResponse;
  } else {
    dispatch(updateNotificationMessage(backendResponse.message));
    dispatch(updateNotificationStatus(400));
    return backendResponse;
  }
};
