import { IEmployee } from "@Models/interfaces/employees";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const DEFAULT_STATE = [];

const initialState: IEmployee = (() => {
  const persistedState = localStorage.getItem("__redux__state__");
  return persistedState ? JSON.parse(persistedState).users : DEFAULT_STATE;
})();

export const currentEmployeeSlice = createSlice({
  name: "currentEmployeeData",
  initialState,
  reducers: {
    updateEmployee: (state, action: PayloadAction<IEmployee>) => {
      return action.payload;
    },
  },
});

export default currentEmployeeSlice.reducer;
export const { updateEmployee } = currentEmployeeSlice.actions;
