import {
  getEmployeeInfoAdapter,
  getEmployeeLoyaltyDataAdapter,
  getEmployeeStatsAdapter,
} from "@Adapters/getEmployees/getEmployees.adapter";
import { AppDispatch } from "@ReduxService/index";
import { resetCurrentEmployeeSales } from "@ReduxService/states/currentEmployeeSalesSlice";
import { updateEmployee } from "@ReduxService/states/currentEmployeeSlice";
import {
  updateEmployeeStats,
  updateLoyaltyStats,
} from "@ReduxService/states/currentEmployeeStatsSlice";

export const getEmployeeData = async (
  idEmployee: number,
  setPromiseStatus: Function,
  dispatch: AppDispatch
) => {
  try {
    const employeeInfo = await getEmployeeInfoAdapter(idEmployee);
    const statsEmployee = await getEmployeeStatsAdapter(idEmployee);
    const loyaltyEmployee = await getEmployeeLoyaltyDataAdapter(idEmployee);

    setPromiseStatus({
      employeeInfo: { status: employeeInfo.status, data: employeeInfo.data },
      employeeStats: { status: statsEmployee.status, data: statsEmployee.data },
      employeeLoyaltyData: {
        status: loyaltyEmployee.status,
        data: loyaltyEmployee.data,
      },
    });

    dispatch(updateEmployee(employeeInfo.data));
    dispatch(updateEmployeeStats(statsEmployee.data));
    dispatch(updateLoyaltyStats(loyaltyEmployee.data));
    dispatch(resetCurrentEmployeeSales());
  } catch (error) {
    setPromiseStatus({
      employeeInfo: { status: 500, data: [] },
      employeeStats: { status: 500, data: [] },
      employeeLoyaltyData: { status: 500, data: [] },
    });
    dispatch(updateEmployee(null));
    dispatch(updateEmployeeStats([]));
    dispatch(updateLoyaltyStats(null));
    dispatch(resetCurrentEmployeeSales());
  }
};
