import { STATUS_BAD_REQUEST } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { deleteEmployeeSchedule } from "@Services/deletes";

export const deleteEmployeeScheduleAdapter = async (
  idSchedule: number,
  dispatch: AppDispatch,
  initialSchedules,
  setInitialSchedules: Function,
  setIsSubmitDisabled: Function,
  setIsSubmitLoading: Function
) => {
  const updateNotification = (response: { data: string; status: number }) => {
    dispatch(updateNotificationMessage(response.data));
    dispatch(updateNotificationStatus(response.status));
  };

  setIsSubmitDisabled(true);
  setIsSubmitLoading(true);

  try {
    const removeScheduleById = (arr, idScheduleToRemove) => {
      return arr.map((day) => {
        return {
          ...day,
          scheduleList: day.scheduleList.filter((schedule) => {
            return schedule.idSchedule !== idScheduleToRemove;
          }),
        };
      });
    };

    if (!String(idSchedule).includes("static")) {
      const response: IResponse = await deleteEmployeeSchedule(idSchedule);
      updateNotification(response);
    }

    const newArray = removeScheduleById(initialSchedules, idSchedule);
    setInitialSchedules(newArray);
    setIsSubmitLoading(false);
  } catch (error) {
    updateNotification({
      data: "Verifique que todos los horarios estén completos",
      status: STATUS_BAD_REQUEST,
    });
  }
};
