// Services
import { editEmployeeService } from "@Services/puts";
import { AppDispatch } from "@ReduxService/index";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { HeaderContextProps } from "@Hooks/useSitesHeaderContext";
import { updateEmployee } from "@ReduxService/states/currentEmployeeSlice";
import { validateEditEmployeeForm } from "@Pages/EditEmployees/views/EmployeeInfo/functions/validateForm";
import { modifyEmployee } from "@ReduxService/states/employeesSlice";
import { updateProfilePicAdapter } from "./updateProfilePic.adapter";
import { STATUS_OK } from "@Models/httpsStatus";

export const editEmployeeAdapter = async (
  data: any,
  dispatch: AppDispatch,
  employeeInitialValues,
  headerContext: HeaderContextProps
) => {
  const birthDate = data.birthDate;
  const body = {
    id: data.idEmployee,
    name: data.name,
    lastname: data.surname,
    dateOfBirth: birthDate,
    alias: data.alias,
    phone: data.phone,
    idRol: data.position,
    documentType: data.documentType,
    document: data.documentNumber,
    sex: data.gender,
  };
  headerContext.handleIsDisabled(true);
  headerContext.handleIsLoading(true);

  const backendResponse: IResponse = await editEmployeeService(body);

  const picturePayload = {
    file: data.profilePicture,
    fileName: data.profilePictureName,
  };

  const pictureResponse: IResponse = await updateProfilePicAdapter(
    data.idEmployee,
    picturePayload
  );

  // ERROR PATH
  if (
    backendResponse.status !== STATUS_OK ||
    pictureResponse.status !== STATUS_OK
  ) {
    headerContext.handleIsDisabled(false);
    headerContext.handleIsLoading(false);
    if (pictureResponse.status !== STATUS_OK) {
      dispatch(updateNotificationMessage(pictureResponse.data));
      dispatch(updateNotificationStatus(pictureResponse.status));
      return {
        isSuccess: false,
      };
    }

    dispatch(updateNotificationMessage(backendResponse.data));
    dispatch(updateNotificationStatus(backendResponse.status));
    return {
      isSuccess: false,
    };
  }

  // SUCCESS PATH
  dispatch(updateNotificationMessage(backendResponse.data));
  dispatch(updateNotificationStatus(backendResponse.status));

  // dispatch(updateEmployee(data));
  const newInitialValues = {
    ...employeeInitialValues,
    name: data.name,
    surname: data.surname,
    phone: data.phone,
    alias: data.alias,
  };
  dispatch(modifyEmployee(newInitialValues));
  headerContext.handleIsDisabled(false);
  headerContext.handleIsLoading(false);

  validateEditEmployeeForm(
    data,
    data,
    headerContext,
    dispatch,
    newInitialValues
  );

  return {
    isSuccess: true,
  };
};
