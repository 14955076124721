import React from "react";

export const clickOusideModalCloses = (modalLayout: any, setShowModal: any) => {
  const handleClickOutside = (event) => {
    if (modalLayout.current && modalLayout.current === event.target) {
      setShowModal(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
};
