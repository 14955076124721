import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface Notification {
  message: string;
  status: number;
  loading: boolean;
}

export const notificationSlice = createSlice({
  name: "notificationConfig",
  initialState: {
    message: "",
    status: 0,
    loading: false,
  },
  reducers: {
    updateNotificationMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    updateNotificationStatus: (state, action: PayloadAction<number>) => {
      state.status = action.payload;
    },
    updateNotificationLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    clearNotification: (state) => {
      state.message = "";
      state.status = 0;
      state.loading = false;
    },
  },
});

export default notificationSlice.reducer;
export const {
  updateNotificationMessage,
  updateNotificationStatus,
  updateNotificationLoading,
  clearNotification,
} = notificationSlice.actions;
