import MainRoutes from "@Routes/MainRoutes";
import { BrowserRouter } from "react-router-dom";
import { HeaderProvider } from "@Hooks/useSitesHeaderContext";

// Redux
import { Provider } from "react-redux";
import { store } from "@ReduxService/index";

export default function App() {
  return (
    <HeaderProvider>
      <Provider store={store}>
        <BrowserRouter basename="/empleados">
          <MainRoutes />
        </BrowserRouter>
      </Provider>
    </HeaderProvider>
  );
}
