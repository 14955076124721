// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OOLJUVmX8Z3gAWK0bpH6 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.sAfFR_i1kJtBJanU8lc3 {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
}

.MUVmRhdgTddaOkf4XQg8 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border: 1px solid #f4eff4;
  border-radius: 8px;
}

.F27clvyQoPNqHDW3gJXM {
  width: 100%;
  display: flex;
  border-top: 0.5px solid #937cf4;
  gap: 24px;
  align-items: center;
  padding: 20px;
}

.BjVBiGRh91lIXskMcElm {
  width: 100%;
  display: flex;
  border-top: 0.5px solid #937cf4;
  gap: 24px;
  align-items: center;
  padding: 20px;
}

.BjVBiGRh91lIXskMcElm:hover {
  background-color: #f0ecfd;
  cursor: pointer;
}

.BjVBiGRh91lIXskMcElm:hover .aofOAPEd6tXESGGSYkdv {
  display: block;
}

.pcSnrhfs9jDEMrF2Fkor {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
}

.yoPf9WIwlTFe0BCDapYn {
  color: #4d4d4d;
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0px 15px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/MainView.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,+BAA+B;EAC/B,SAAS;EACT,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,aAAa;EACb,+BAA+B;EAC/B,SAAS;EACT,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":[".loadingContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  gap: 24px;\n}\n\n.unauthorized {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  border: 1px solid #f4eff4;\n  border-radius: 8px;\n}\n\n.containerEmpleadoTabla {\n  width: 100%;\n  display: flex;\n  border-top: 0.5px solid #937cf4;\n  gap: 24px;\n  align-items: center;\n  padding: 20px;\n}\n\n.containerEmpleadoTablaHover {\n  width: 100%;\n  display: flex;\n  border-top: 0.5px solid #937cf4;\n  gap: 24px;\n  align-items: center;\n  padding: 20px;\n}\n\n.containerEmpleadoTablaHover:hover {\n  background-color: #f0ecfd;\n  cursor: pointer;\n}\n\n.containerEmpleadoTablaHover:hover .containerIconsHide {\n  display: block;\n}\n\n.noEmployeesContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  padding-top: 20px;\n}\n\n.noEmployeesText {\n  color: #4d4d4d;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 18px;\n  font-weight: 700;\n  margin: 10px 0px 15px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": `OOLJUVmX8Z3gAWK0bpH6`,
	"container": `sAfFR_i1kJtBJanU8lc3`,
	"unauthorized": `MUVmRhdgTddaOkf4XQg8`,
	"containerEmpleadoTabla": `F27clvyQoPNqHDW3gJXM`,
	"containerEmpleadoTablaHover": `BjVBiGRh91lIXskMcElm`,
	"containerIconsHide": `aofOAPEd6tXESGGSYkdv`,
	"noEmployeesContainer": `pcSnrhfs9jDEMrF2Fkor`,
	"noEmployeesText": `yoPf9WIwlTFe0BCDapYn`
};
export default ___CSS_LOADER_EXPORT___;
