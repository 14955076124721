// Components
import { Route, Routes } from "react-router-dom";
import {
  NotFoundPage,
  ProtectedRoute,
  SlideNotification,
  // @ts-ignore
} from "@viuti/recursos";

// Routes
import { ROUTE_EMPLOYEES } from "../models/routes";
import MainView from "@Pages/MainView/MainView";
import InviteEmployees from "@Pages/InviteEmployees/InviteEmployees";
import EditEmployees from "@Pages/EditEmployees/EditEmployees";

// Redux
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { clearNotification } from "@ReduxService/states/notificationSlice";
import { AddMember } from "@Pages/AddMember/AddMember";
import { INVITE_EMPLOYEES_UUID } from "@Models/consts/securityAccess";

const MainRoutes = () => {
  const dispatch = useAppDispatch();

  // Slide Notification
  const notificationState = useAppSelector((state) => state.notificationConfig);

  return (
    <>
      <Routes>
        <Route path={ROUTE_EMPLOYEES.ROUTE_MAIN_VIEW} element={<MainView />} />
        <Route
          path={ROUTE_EMPLOYEES.ROUTE_INVITE_EMPLOYEES}
          element={
            <ProtectedRoute
              accessKeyModule={INVITE_EMPLOYEES_UUID}
              element={<InviteEmployees />}
            />
          }
        />
        <Route
          path={ROUTE_EMPLOYEES.ROUTE_ADD_EMPLOYEES}
          element={<AddMember />}
        />
        <Route
          path={`${ROUTE_EMPLOYEES.ROUTE_EDIT_EMPLOYEES}/:idEmployee`}
          element={<EditEmployees />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <SlideNotification
        state={notificationState}
        clearStatus={() => dispatch(clearNotification())}
      />
    </>
  );
};

export default MainRoutes;
