import { STATUS_BAD_REQUEST, STATUS_OK } from "@Models/httpsStatus";
import { ISchedulesArray } from "@Models/interfaces/schedules";
import { AppDispatch } from "@ReduxService/index";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { editEmployeeSchedulesService } from "@Services/puts";

export const updateEmployeeSchedulesAdapter = async (
  dispatch: AppDispatch,
  idEmployee: number,
  schedules: ISchedulesArray[]
) => {
  try {
    const filterSchedules = schedules.map((scheduleList) => {
      const temp = scheduleList.scheduleList.map((schedule) => {
        const noShedules = schedule.startTime === "" || schedule.endTime === "";
        const noIdSchedule = String(schedule.idSchedule).includes("static");
        let formattedStartTime;
        let formattedEndTime;

        if (schedule.isAvailable && noShedules) {
          throw new Error("Verifique que todos los horarios estén completos");
        } else if (noIdSchedule && noShedules) {
          return undefined;
        }

        if (schedule.startTime.includes("AM")) {
          formattedStartTime = schedule.startTime.replace(" AM", "");
        } else {
          const startTime = schedule.startTime.replace(" PM", "").split(":");
          if (Number(startTime[0]) !== 12) {
            formattedStartTime = `${Number(startTime[0]) + 12}:${startTime[1]}`;
          } else {
            formattedStartTime = `${Number(startTime[0])}:${startTime[1]}`;
          }
        }

        if (schedule.endTime.includes("AM")) {
          formattedEndTime = schedule.endTime.replace(" AM", "");
        } else {
          const endTime = schedule.endTime.replace(" PM", "").split(":");
          if (Number(endTime[0]) !== 12) {
            formattedEndTime = `${Number(endTime[0]) + 12}:${endTime[1]}`;
          } else {
            formattedEndTime = `${Number(endTime[0])}:${endTime[1]}`;
          }
        }

        return {
          id: !String(schedule.idSchedule).includes("static")
            ? schedule.idSchedule
            : null,
          dayOfTheWeek: scheduleList.idDay + 1,
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          estado: schedule.isAvailable ? 1 : 0,
        };
      });
      return temp;
    });

    const separatedSchedules = filterSchedules
      .flatMap((dia) =>
        dia.map((horario) => {
          if (!horario) {
            return undefined;
          }
          return {
            ...horario,
            dayOfTheWeek: horario.dayOfTheWeek,
          };
        })
      )
      .filter((schedule) => schedule !== undefined);

    const payload = {
      idEmployee: idEmployee,
      scheduleRegistration: separatedSchedules,
    };

    const response: IResponse = await editEmployeeSchedulesService(payload);

    if (response.status !== STATUS_OK) {
      dispatch(updateNotificationMessage("Error al actualizar los horarios."));
      dispatch(updateNotificationStatus(400));
      return;
    }

    dispatch(updateNotificationMessage("Aguarde un momento..."));
    dispatch(updateNotificationStatus(200));

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (error) {
    dispatch(
      updateNotificationMessage(
        "Error al actualizar los horarios. " + error.message
      )
    );
    dispatch(updateNotificationStatus(STATUS_BAD_REQUEST));
    return;
  }
};
