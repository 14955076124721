// Interfaces
import { IEmployee } from "@Models/interfaces/employees";
import { AppDispatch } from "@ReduxService/index";

// Services
import { deleteEmployeeInviteService } from "@Services/deletes";

// Redux
import {
  addEmployee,
  deleteEmployee,
} from "@ReduxService/states/employeesSlice";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { postEmployeeService, resendInviteService } from "@Services/posts";
import { capitalizeFirstLetterOfEachWord } from "@Utilities/texts";
import { STATUS_OK } from "@Models/httpsStatus";

export const deleteEmployeeInviteAdapter = async (
  idInvite: number,
  dispatch: AppDispatch,
  setIsLoadingDelete,
  setModalUnsavedChanges
) => {
  setIsLoadingDelete(true);
  dispatch(updateNotificationMessage("Eliminando invitación..."));
  dispatch(updateNotificationStatus(200));

  const backendResponse: IResponse = await deleteEmployeeInviteService(
    idInvite
  );

  // ERROR PATH
  if (backendResponse.status !== 200) {
    dispatch(updateNotificationMessage(backendResponse.message));
    dispatch(updateNotificationStatus(backendResponse.status));
    setIsLoadingDelete(false);
    return;
  }
  setModalUnsavedChanges(false);
  setIsLoadingDelete(false);

  // SUCCESS PATH
  dispatch(deleteEmployee(idInvite));
  dispatch(updateNotificationMessage(backendResponse.data));
  dispatch(updateNotificationStatus(200));
};

export const resendEmployeeInviteAdapter = async (
  idEmployee: number,
  dispatch: AppDispatch
) => {
  const backendResponse: IResponse = await resendInviteService(idEmployee);

  // ERROR PATH
  if (backendResponse.status !== 200) {
    dispatch(updateNotificationMessage(backendResponse.message));
    dispatch(updateNotificationStatus(backendResponse.status));
    return;
  }

  // SUCCESS PATH
  dispatch(updateNotificationMessage(backendResponse.data));
  dispatch(updateNotificationStatus(200));
};

export const sendEmployeeInviteAdapter = async (
  data: any,
  handleResetForm: Function,
  selectedPosition: string,
  dispatch: AppDispatch,
  headerContext: any,
  setExistEmployeeId: Function
) => {
  headerContext.handleIsDisabled(true);
  headerContext.handleIsLoading(true);
  const backendResponse: IResponse = await postEmployeeService({
    role: data.position,
    email: data.email,
    name: capitalizeFirstLetterOfEachWord(data.name),
  });

  // ERROR PATH
  if (backendResponse.status !== STATUS_OK) {
    headerContext.handleIsLoading(false);
    headerContext.handleIsDisabled(false);

    if (backendResponse.data?.idEmployee) {
      setExistEmployeeId(backendResponse.data.idEmployee);
    } else {
      dispatch(updateNotificationMessage(backendResponse.message));
      dispatch(updateNotificationStatus(backendResponse.status));
    }
    return backendResponse;
  }

  // SUCCESS PATH
  const userData: IEmployee = {
    surname: "",
    email: backendResponse.data.email,
    idEmployee: backendResponse.data.id,
    profilePicture: "",
    name: backendResponse.data.name,
    services: [],
    position: selectedPosition,
    isAdmin: false,
    userWithAccess: true,
  };

  dispatch(updateNotificationMessage("Invitación enviada con éxito"));
  dispatch(updateNotificationStatus(200));
  dispatch(addEmployee(userData));
  headerContext.handleIsLoading(false);
  headerContext.handleIsDisabled(false);
  handleResetForm();
  return backendResponse;
};
