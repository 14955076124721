import { employeeBaseUrl, ApiResponse, getData } from "@Services/constServices";

export const getCommissionsToPayForEmployee_service = async (
  employeeId: number,
  startDate: string,
  endDate: string,
  commisionId: number
): Promise<ApiResponse> => {
  const url = `${employeeBaseUrl}CommissionEmployee/GetCommisiontoPayForEmployee?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}&commisionId=${commisionId}`;
  const successMessage =
    "Se ha obtenido el listado de comisiones de los empleados exitosamente";
  const errorMessage =
    "Error al obtener el listado de comisiones de los empleados, intente nuevamente";
  return await getData(url, successMessage, errorMessage);
};
