import { AppDispatch } from "@ReduxService/index";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { getPositionsService } from "@Services/gets";
import { capitalizeFirstLetter } from "@Utilities/texts";

export const getPositionsAdapter = async (dispatch: AppDispatch) => {
  const response: IResponse = await getPositionsService();

  if (!response.isSuccess) {
    dispatch(updateNotificationMessage(response.message));
    dispatch(updateNotificationStatus(response.status));
    return response;
  }

  const ADMIN_ROLE = 1;

  const positions = response.data
    .filter((position) => position?.especial !== ADMIN_ROLE)
    .map((position) => ({
      value: position.rolId,
      name: position.nameRol,
    }));

  const sortedPositions = positions.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const styledPositions = sortedPositions.map((position) => ({
    value: position.value,
    name: capitalizeFirstLetter(position.name),
  }));

  return {
    ...response,
    data: styledPositions,
  };
};
