import { getScheduleEmployeeService } from "@Services/gets";
import { v4 as uuidv4 } from "uuid";

export const getEmployeeSchedulesAdapter = async (idEmployee: number) => {
  const response: IResponse = await getScheduleEmployeeService(idEmployee);

  if (!response.isSuccess) {
    return {
      ...response,
      data: [],
    };
  }

  const separateScheduleByDays = () => {
    const daysMap = new Map();

    // Initializes an array with the names of the days of the week
    const daysOfWeek = [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
      "Domingo",
    ];

    // Fills the map with all the days of the week and empty schedules by default
    daysOfWeek.forEach((dayName, index) => {
      daysMap.set(dayName, [
        {
          startTime: "",
          endTime: "",
          // idSchedule: '',
          idSchedule: "static" + uuidv4(),
          isAvailable: false,
        },
      ]);
    });

    // Fills the map with the obtained schedules
    response.data.forEach((schedule) => {
      const { dayOfTheWeek, startTime, endTime, idSchedule, activeBit } =
        schedule;
      const dayName = daysOfWeek[dayOfTheWeek - 1];

      if (!dayName) {
        return; // Ignores unknown days
      }

      if (!daysMap.has(dayName)) {
        daysMap.set(dayName, []);
      }

      // Checks if a schedule already exists in the day list and deletes the default record if there is
      if (
        daysMap.get(dayName).length === 1 &&
        !daysMap.get(dayName)[0].idSchedule
      ) {
        daysMap.get(dayName).pop();
      }

      // Remove the existing schedule if it exists
      const existingScheduleIndex = daysMap
        .get(dayName)
        .findIndex((schedule) => {
          return !String(schedule.idSchedule).includes("static");
        });

      if (existingScheduleIndex === -1) {
        daysMap.get(dayName).splice(existingScheduleIndex, 1);
      }

      // Push the new schedule
      daysMap.get(dayName).push({
        startTime: startTime,
        endTime: endTime,
        idSchedule: idSchedule,
        isAvailable: activeBit === 1,
      });

      // Sort the schedules by startTime
      daysMap.get(dayName).sort((a, b) => {
        return (
          new Date("1970/01/01 " + a.startTime).getTime() -
          new Date("1970/01/01 " + b.startTime).getTime()
        );
      });
    });

    //@ts-ignore
    const scheduleByDays = [...daysMap.entries()].map(
      ([day, scheduleList], idDay) => ({
        idDay,
        day,
        scheduleList,
      })
    );

    return scheduleByDays;
  };

  const schedules = separateScheduleByDays();

  return {
    ...response,
    data: schedules,
  };
};
