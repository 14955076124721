import { ApiResponse, deleteData, teamBaseUrl } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const deleteInvitationEmployee_Service = async (
  idEmployee: any
): Promise<ApiResponse> => {
  const url = `${teamBaseUrl}/DeleteInvitationEmployee?idEmployee=${idEmployee}`;
  const elimina_invitacion_empleado = 47;
  trackUserAction(elimina_invitacion_empleado);
  const successMessage =
    "Se ha eliminado la invitacion del empleado exitosamente";
  const errorMessage = "Error al eliminar la invitacion del empleado";
  return await deleteData(url, successMessage, errorMessage);
};
