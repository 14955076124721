import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./MainView.module.css";
import {
  HeaderNavigation,
  SearchWithColoredBorder,
  UnauthorizedMessage,
  validateModuleAccess,
  getUserPermissions,
} from "../../recursos";
import EmployeeRow from "@Components/ui/molecules/EmployeeRow/EmployeeRow";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { updateEmployees } from "@ReduxService/states/employeesSlice";
import { goToAddEmployee, goToInviteEmployee } from "../../utilities/navigate";
import { getEmployeesAdapter } from "@Adapters/getEmployees/getEmployees.adapter";
import { LoadingPulseIcon } from "@Models/icons";
import { STATUS_OK } from "@Models/httpsStatus";
import {
  INVITE_EMPLOYEES_UUID,
  SHOW_EMPLOYEES_UUID,
} from "@Models/consts/securityAccess";
import AvailableSeats from "@Components/ui/molecules/AvailableSeats/AvailableSeats";

const MainView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userPermissions = getUserPermissions();

  const [valueSearch, setValueSearch] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPagination, setCurrentPagination] = useState(1);
  const elementsPerPage = 10;

  //? State for api calls handling
  const [promiseStatus, setPromiseStatus] = useState({
    employees: { status: 0, data: null },
  });

  useEffect(() => {
    // Fetch employees data
    if (validateModuleAccess(SHOW_EMPLOYEES_UUID, false)) {
      getEmployeesAdapter(setIsLoading, dispatch).then((res) => {
        if (res.status === STATUS_OK) {
          setPromiseStatus({
            employees: { status: res.status, data: res.data },
          });
          dispatch(updateEmployees(res.data));
        }
      });
    } else {
      setIsLoading(false);
      setPromiseStatus({
        employees: { status: STATUS_OK, data: [] },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //? State for employees list
  const employeesState = useAppSelector((state) => state.employeesList);

  useEffect(() => {
    setCurrentPagination(1);
  }, [valueSearch]);

  useEffect(() => {
    // Filter employees based on search or show all if search is empty
    const filtered = valueSearch
      ? employeesState?.filter((empleado) => {
          const name = empleado?.name?.toLowerCase();
          const surname = empleado?.surname?.toLowerCase();
          const searchValue = valueSearch?.toLowerCase();
          return name?.includes(searchValue) || surname?.includes(searchValue);
        })
      : [...employeesState];

    setFilteredEmployees(filtered);
  }, [valueSearch, employeesState]);

  const availableSeats = getUserPermissions().employeesLimitCount;
  const totalEmployees = employeesState.filter(
    (employee) => employee.userWithAccess
  ).length;

  const exceededEmployees = availableSeats - totalEmployees > 0 ? false : true;

  const startIndex = (currentPagination - 1) * elementsPerPage;
  const endIndex = startIndex + elementsPerPage;

  if (isLoading) {
    return (
      <div id="viuti-front-mainContent">
        <div className={style.loadingContainer}>
          <img src={LoadingPulseIcon} alt="Cargando..." />
        </div>
      </div>
    );
  }

  return (
    <div id="viuti-front-mainContent">
      <div className={style.container}>
        <HeaderNavigation
          title={"Mi equipo"}
          previousAction={false}
          previousActionMovement={"close"}
          buttonProps={{
            textBttn: "Invitar miembro",
            handleClick: () =>
              validateModuleAccess(INVITE_EMPLOYEES_UUID) &&
              goToInviteEmployee(navigate),
            isDisabled: exceededEmployees,
            isPrimary: true,
          }}
          buttonTwoProps={{
            textBttn: "Agregar empleado",
            handleClick: () => goToAddEmployee(navigate),
            isDisabled: false,
            isPrimary: false,
          }}
        />
        <AvailableSeats />
        {!validateModuleAccess(SHOW_EMPLOYEES_UUID, false) ? (
          <div className={style.unauthorized}>
            <UnauthorizedMessage
              restrictedFeatureName={"Listado de empleados"}
              restrictedUUID={SHOW_EMPLOYEES_UUID}
            />
          </div>
        ) : (
          <>
            <div className={style.containerSearch}>
              <SearchWithColoredBorder
                label={""}
                value={valueSearch}
                placeholder={"Buscar por nombre de empleado"}
                setValue={setValueSearch}
                withoutIcon={true}
                clearInput={false}
              />
            </div>
            {filteredEmployees?.length === 0 ? (
              <div className={style.noEmployeesContainer}>
                <p className={style.noEmployeesText}>
                  No se encontraron empleados.
                </p>
              </div>
            ) : (
              <EmployeeRow
                filteredEmployees={filteredEmployees?.slice(
                  startIndex,
                  endIndex
                )}
                dispatch={dispatch}
                currentPagination={currentPagination}
                setCurrentPagination={setCurrentPagination}
                elementsPerPage={elementsPerPage}
                employeesState={employeesState}
                options={filteredEmployees}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MainView;
