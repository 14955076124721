import { useContext } from "react";

// Styles
//@ts-ignore
import style from "./EmployeeStats.module.css";

// Redux
import { useAppSelector } from "@Hooks/store";
import { StatsCard } from "@Components/ui/molecules/StatsCard/StatsCard";

// Context
import {
  HeaderContext,
  HeaderContextProps,
} from "@Hooks/useSitesHeaderContext";

const EmployeeStats = () => {
  const headerContext = useContext(HeaderContext) as HeaderContextProps;
  headerContext.handleIsHidden(true);

  //? State for the stats of selected employee
  const selectedEmployeeStats = useAppSelector(
    (state) => state.currentEmployeeStats.stats
  );
  const selectedEmployeeLoyalty = useAppSelector(
    (state) => state.currentEmployeeStats.loyalty
  );

  //? Periods stats
  const dailyStats = selectedEmployeeStats[0];
  const weeklyStats = selectedEmployeeStats[1];
  const monthlyStats = selectedEmployeeStats[2];
  const annualStats = selectedEmployeeStats[3];

  return (
    <div className={style.viewContainer}>
      <div className={style.periodStatsContainer}>
        <StatsCard stats={dailyStats} />
        <StatsCard stats={weeklyStats} />
        <StatsCard stats={monthlyStats} />
        <StatsCard stats={annualStats} />
      </div>
      <div className={style.clientsLoyaltyStatsContainer}>
        <div className={style.clientsLoyaltyStatsContent}>
          <h1 className={style.clientsLoyaltyTitle}>
            Porcentaje de fidelización
          </h1>
          <div className={style.clientsStatsContent}>
            <h1 className={style.clientsPercentageStats}>
              {selectedEmployeeLoyalty.loyaltyPercentage}
            </h1>
            <div className={style.clientsStatContainer}>
              <h1 className={style.statTitle}>Total de clientes atendidos</h1>
              <span className={style.statValue}>
                {selectedEmployeeLoyalty.clientsServed}
              </span>
            </div>
            <div className={style.clientsStatContainer}>
              <h1 className={style.statTitle}>
                Total de clientes que regresaron
              </h1>
              <span className={style.statValue}>
                {selectedEmployeeLoyalty.usualClients}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeStats;
