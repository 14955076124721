// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ftplK9OUktvaUuyW38Wv {
  max-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  margin-top: 1rem;
}
.QLNqiUFNp45TD_0VjD10 {
  font-weight: 700;
  font-size: var(--font-size-h5);
  color: #45348e;
}

.ftplK9OUktvaUuyW38Wv h3 {
  font-weight: 600;
  font-size: var(--font-size-reading);
}
`, "",{"version":3,"sources":["webpack://./src/pages/AddMember/AddMember.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mCAAmC;AACrC","sourcesContent":[".container {\n  max-height: -webkit-fill-available;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  overflow-y: auto;\n  margin-top: 1rem;\n}\n.viewTitleContent {\n  font-weight: 700;\n  font-size: var(--font-size-h5);\n  color: #45348e;\n}\n\n.container h3 {\n  font-weight: 600;\n  font-size: var(--font-size-reading);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ftplK9OUktvaUuyW38Wv`,
	"viewTitleContent": `QLNqiUFNp45TD_0VjD10`
};
export default ___CSS_LOADER_EXPORT___;
