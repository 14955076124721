import { postCreateEmployees_service } from "@Services/Employee/postCreateEmployee.service";

export const postCreateEmployees_adapter = async (data) => {
  const response = await postCreateEmployees_service(data);
  if (!response.isSuccess) {
    return response;
  }

  const adaptResponse = {
    isSuccess: response.isSuccess,
    status: response.status,
    data: response.data,
    message: response.message,
  };

  return adaptResponse;
};
