import { AppDispatch } from "@ReduxService/index";
import {
  IListOfEmployeeCommissions,
  updateListOfEmployeeCommissions,
} from "@ReduxService/states/listOfEmployeeCommissionsSlice";
import {
  updateNotificationStatus,
  updateNotificationMessage,
} from "@ReduxService/states/notificationSlice";

import { getListOfEmployeeCommissions_service } from "@Services/Employee/getListOfEmployeeCommissions.service";

export const getListOfEmployeeCommissions_adapter = async (
  dispatch: AppDispatch,
  employeeId: number,
  setLoader: (loading: boolean) => void
) => {
  setLoader(true);
  const response = await getListOfEmployeeCommissions_service(employeeId);
  if (!response.isSuccess) {
    dispatch(updateNotificationMessage(response.message));
    dispatch(updateNotificationStatus(response.status));
    dispatch(updateListOfEmployeeCommissions([]));
  } else {
    const adaptedResponse = response.data.map(
      (commission: IListOfEmployeeCommissions) => ({
        commisionId: commission.commisionId,
        commisionName: commission.commisionName,
        typeAmount: commission.typeAmount,
        typeAmountDescription: commission.typeAmountDescription,
        amount: commission.amount,
        goal: commission.goal,
        methodId: commission.methodId,
        methodDescription: commission.methodDescription,
      })
    );
    dispatch(updateListOfEmployeeCommissions(adaptedResponse.reverse()));
  }
  setLoader(false);
};
