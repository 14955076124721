import { isServiceSelected } from "./isServiceSelected";

export const handleServiceChange = (
  service: IService,
  selectedServices: IService[],
  setSelectedServices: Function
) => {
  if (isServiceSelected(service, selectedServices)) {
    if (selectedServices.length > 1) {
      setSelectedServices((prevSelectedServices) =>
        prevSelectedServices?.filter(
          (prevService) => prevService?.idService !== service?.idService
        )
      );
    }
  } else {
    setSelectedServices((prevSelectedServices) => [
      ...prevSelectedServices,
      service,
    ]);
  }
};
