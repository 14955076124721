import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { deleteInvitationEmployee_Service } from "@Services/Employee/DeleteInvitationEmployee.service";

export const deleteInvitationEmployee_adapter = async (
  idEmployee,
  dispatch,
  setIsLoadingDelete,
  setModalDeleteInvitation
) => {
  setIsLoadingDelete(true);
  const response = await deleteInvitationEmployee_Service(idEmployee);
  if (!response.isSuccess) {
    dispatch(updateNotificationMessage(response.message));
    dispatch(updateNotificationStatus(response.status));
    setIsLoadingDelete(false);
    setModalDeleteInvitation(false);
    return;
  }

  dispatch(updateNotificationMessage(response.message));
  dispatch(updateNotificationStatus(response.status));
  setIsLoadingDelete(false);
  setModalDeleteInvitation(false);
  setTimeout(() => {
    window.location.reload();
  }, 1000);
};
