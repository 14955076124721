import { useEffect, useRef, useState } from "react";

// Styles
//@ts-ignore
import style from "./SchedulesModal.module.css";

// Components
//@ts-ignore
import { ButtonWithoutIcon, Icon, SchedulesWrapper } from "@viuti/recursos";

// Services

// Models
import {
  IEmployeeSchedule,
  ISchedulesArray,
} from "@Models/interfaces/schedules";

// Funtions
import { TimesIcon } from "@Models/icons";
import { clickOusideModalCloses } from "@Components/ui/molecules/ServicesModal/functions/clickOusideModalCloses";
import { updateEmployeeSchedulesAdapter } from "@Adapters/getSchedules/updateSchedules.adapter";
import { useAppDispatch } from "@Hooks/store";
import { deleteEmployeeScheduleAdapter } from "@Adapters/getSchedules/deleteSchedule.adapter";

interface SchedulesModalProps {
  initialSchedulesState: ISchedulesArray[];
  setInitialSchedulesState: Function;
  visible: boolean;
  setVisible: Function;
  idEmployee: number;
}

const SchedulesModal = ({
  initialSchedulesState,
  setInitialSchedulesState,
  visible,
  setVisible,
  idEmployee,
}: SchedulesModalProps) => {
  const dispatch = useAppDispatch();
  const [employeeSchedules, setEmployeeSchedules] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  // Close when clicking outside the modal
  const modalLayout = useRef(null);
  useEffect(() => {
    clickOusideModalCloses(modalLayout, setVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalLayout]);

  // Apply initial services state
  useEffect(() => {
    if (initialSchedulesState?.length > 0) {
      setEmployeeSchedules(initialSchedulesState);
    }
  }, [initialSchedulesState]);

  // Disable submit button if there are no changes
  useEffect(() => {
    if (
      JSON.stringify(initialSchedulesState) ===
      JSON.stringify(employeeSchedules)
    ) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [initialSchedulesState, employeeSchedules]);

  const submitSchedules = async () => {
    setIsSubmitLoading(true);
    setIsSubmitDisabled(true);
    await updateEmployeeSchedulesAdapter(
      dispatch,
      idEmployee,
      employeeSchedules
    );
    setIsSubmitLoading(false);
    setIsSubmitDisabled(false);
  };

  const onDeleteSchedule = async (schedule: IEmployeeSchedule) => {
    await deleteEmployeeScheduleAdapter(
      schedule.idSchedule,
      dispatch,
      employeeSchedules,
      setInitialSchedulesState,
      setIsSubmitDisabled,
      setIsSubmitLoading
    );
  };

  return (
    <div
      className={`${style.layoutContainer}  ${
        visible ? style.visible : style.hidden
      }`}
      ref={modalLayout}
    >
      <div className={style.modalContainer}>
        <div className={style.headerContainer}>
          <h1 className={style.headerTitle}>Horarios</h1>
          <button
            className={style.closeModalBtn}
            onClick={() => setVisible(false)}
          >
            <Icon path={TimesIcon} alt="X" size={14} color={"#B4B4B4"} />
          </button>
        </div>
        <div className={style.schedulesContainer}>
          <SchedulesWrapper
            schedules={employeeSchedules}
            setSchedules={setEmployeeSchedules}
            scheduleActions={{ onDelete: onDeleteSchedule }}
          />
        </div>
        <div className={style.bottomContainer}>
          <ButtonWithoutIcon
            isPrimary
            textBttn={"Guardar"}
            handleClick={submitSchedules}
            isDisabled={isSubmitDisabled}
            isLoading={isSubmitLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default SchedulesModal;
